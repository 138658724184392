import styled, { css } from "styled-components";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  box-shadow: ${({ theme }) => theme.boxShadows.level3};
  background-color: var(--white);
  border-radius: 4px;
  margin: 0 auto !important;
  widh: 80%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  background: none;

  > * {
    box-sizing: border-box;
  }

  > section {
    margin: 0;
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
  }

  h6 {
    font-size: 16.8px;
    font-weight: 600;
  }

  textarea {
    box-sizing: border-box;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: auto;
    min-width: 500px;
    max-width: 800px;
    height: auto;
    max-height: 80%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`;
