import { createSlice } from "@reduxjs/toolkit";
import Api from "api/Api";

const api = new Api();

const initialState = {
  id: "",
  login: "",
  lang: "en",
  status: "idle",
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => {
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const { resetUser } = userSlice.actions;

export default userSlice.reducer;

export const selectUserInfo = (state) => state.user;
