import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentBoardData } from "store/currentBoardSlice";
import { selectCompanyInfo } from "store/companySlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import { useTranslation } from "react-i18next";

import Topbar from "layout/atoms/Topbar";
import GridBoard from "layout/atoms/GridBoard";
import ContentBox from "layout/atoms/ContentBox";

function BoardWidget() {
  const dispatch = useDispatch();
  const companyInfo = useSelector(selectCompanyInfo);
  const currentBoardData = useSelector(selectCurrentBoardData);
  const api = new Api();
  const { t } = useTranslation();
  const [boardHash, setBoardHash] = useState("");

  useEffect(() => {
    fetchWidgetSetup();
  }, [dispatch]);

  const fetchWidgetSetup = async () => {
    await api.getWidgetSetup(currentBoardData.id).then((widgetSetup) => {
      setBoardHash(widgetSetup.boardHash);
    });
  };

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("boardsDetails.widget.header")}</h2>
        </div>
      </Topbar>
      <GridBoard>
        <ContentBox>
          <p>{t("boardsDetails.widget.first")}</p>
          <code>
            <p>
              &lt;script src="
              <br />
              https://app.ideolo.co/widget/index.js
              <br />
              "&gt;&lt;/script&gt;
            </p>
          </code>
          <p>{t("boardsDetails.widget.second")}</p>
          <code>
            <p>
              &lt;div&gt;
              <br />
              <span></span>id="ideolo-board"
              <br />
              <span></span>data-base-url="https://app.ideolo.co/"
              <br />
              <span></span>data-board-hash="{boardHash}"
              <br />
              <span></span>data-sso-token="
              <mark>{t("boardsDetails.widget.ssoToken")}</mark>"
              <br />
              &gt;&lt;/div&gt;
            </p>
          </code>
          <p>{t("boardsDetails.widget.third")}</p>
          <code>
            <p>
              &lt;dependency&gt;
              <br />
              <span></span>&lt;groupId&gt;com.auth0&lt;/groupId&gt;
              <br />
              <span></span>&lt;artifactId&gt;java-jwt&lt;/artifactId&gt;
              <br />
              <span></span>&lt;version&gt;3.18.3&lt;/version&gt;
              <br />
              &lt;/dependency&gt;
            </p>
          </code>
          <p>{t("boardsDetails.widget.fourth")}</p>
          <code>
            <p>
              public class IdeoloSSOTokenGenerator &#123;
              <br />
              <br />
              <span></span>private final static String COMPANY_SECRET = "
              {companyInfo.secret}";
              <br />
              <span></span>public String generateToken(String userId, String
              userDisplayedName) &#123;
              <br />
              <br />
              <span></span>
              <span></span>Map&lt;String, String&gt; payload = new
              HashMap&lt;&gt;();
              <br />
              <span></span>
              <span></span>payload.put("id", userId);
              <br />
              <span></span>
              <span></span>payload.put("name", userDisplayedName);
              <br />
              <br />
              <span></span>
              <span></span>Algorithm algorithm =
              Algorithm.HMAC256(COMPANY_SECRET);
              <br />
              <br />
              <span></span>
              <span></span>return JWT.create()
              <br />
              <span></span>
              <span></span>
              <span></span>.withPayload(payload)
              <br />
              <span></span>
              <span></span>
              <span></span>.sign(algorithm); <br />
              <span></span>
              &#125;
              <br />
              &#125;
              <br />
            </p>
          </code>
        </ContentBox>
      </GridBoard>
    </MainContainer>
  );
}

export default BoardWidget;
