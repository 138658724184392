import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { removeToast } from "store/toastsSlice";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";

import successSvg from "assets/icons/all-done.svg";
import errorSvg from "assets/icons/close.svg";
import infoSvg from "assets/icons/info.svg";
import warningSvg from "assets/icons/warning.svg";
import CloseSvg from "assets/icons/close.svg";

import { Wrapper } from "./styles";

const Toast = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const icons = {
    success: successSvg,
    error: errorSvg,
    info: infoSvg,
    warning: warningSvg,
  };

  const handleClose = () => {
    setActive(false);
    setTimeout(() => dispatch(removeToast(data.id)), 500);
  };

  useEffect(() => {
    setTimeout(() => setActive(true), 100);
    setTimeout(() => handleClose(), 5000);
  }, [dispatch]);

  return (
    <Wrapper active={active} type={data.type}>
      <div>
        <Icon src={icons[data.type]} />
        <div>
          <h4>{data.title ? t(data.title) : t(`toasts.error.title`)}</h4>
          <p>
            {data.description ? t(data.description) : t(`toasts.error.title`)}
          </p>
        </div>
      </div>
      <Button size="sm-square" color="white" onClick={() => handleClose()}>
        <Icon src={CloseSvg} />
      </Button>
    </Wrapper>
  );
};

export default Toast;
