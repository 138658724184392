import React, { useState, useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentBoard } from "store/currentBoardSlice";
import { logIn, selectAuthInfo } from "store/authSlice";
import { addToast } from "store/toastsSlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Logo from "layout/atoms/Logo";

const ResetPasswordForm = (props) => {
  const dispatch = useDispatch();
  const authInfo = useSelector(selectAuthInfo);
  let api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "resetPassword",
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    dispatch(clearCurrentBoard());
  }, [dispatch]);

  return authInfo.isUserLoggedIn ? (
    <Navigate to={`/`} />
  ) : (
    <MainContainer alignCenter>
      <Logo circle />
      <ContentBox
        size="s"
        centeredHeader
        header={<h2>{t("sent.title")}</h2>}
        footer={
          <>
            <div />
            <Button to="/login" size="lg" color="primary">
              {t("backToLogin")}
            </Button>
          </>
        }
      >
        <p>{t("sent.checkMail")}</p>
      </ContentBox>
    </MainContainer>
  );
};

export default ResetPasswordForm;
