import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Hamburger from "layout/atoms/Hamburger/";
import Icon from "layout/atoms/Icon/";
import Logo from "layout/atoms/Logo/";

import { Header, NavItem, NavList, Wrapper } from "./styles";

const MenuToolbar = ({ isActive, navItems }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "menuToolbar",
  });

  const toggleMenu = () => setMenuOpened((prevState) => !prevState);

  const mapNavItems = (items) =>
    items.map((item) => (
      <NavItem key={item.id} onClick={toggleMenu}>
        {item.to.startsWith("https://") ? (
          <a href={item.to} target="_blank" rel="noopener noreferrer">
            <Icon src={item.icon} />
            <p>{t(item.name)}</p>
          </a>
        ) : (
          <NavLink to={item.to}>
            {/* <NavLink to={item.to} activeClassName="active"> */}
            <Icon src={item.icon} />
            <p>{t(item.name)}</p>
          </NavLink>
        )}
      </NavItem>
    ));

  return (
    <Wrapper active={isActive}>
      <Header>
        <Logo />
      </Header>
      <Hamburger isOpened={menuOpened} onClick={toggleMenu} />
      <NavList isOpened={menuOpened}>
        <ul>{mapNavItems(navItems.filter((navItem) => navItem.top))}</ul>
        <ul>{mapNavItems(navItems.filter((navItem) => !navItem.top))}</ul>
      </NavList>
    </Wrapper>
  );
};

export default MenuToolbar;
