import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

import {fetchBoards, selectAllBoards} from "store/boardsSlice";
import {clearCurrentBoard} from "store/currentBoardSlice";
import MainContainer from "layout/atoms/MainContainer";

import Button from "layout/atoms/Button";
import GridBoardList from "layout/atoms/GridBoardList";
import Icon from "layout/atoms/Icon";
import Loader from "layout/atoms/Loader";
import Topbar from "layout/atoms/Topbar";
import Alert from "layout/atoms/Alert";

import BoardCard from "layout/molecules/BoardCard";
import EmptyState from "layout/molecules/EmptyState";

import AddBoardModal from "layout/organisms/AddBoardModal";

import plusSquare from "assets/icons/plus-square.svg";

import ContentBox from "../../../layout/atoms/ContentBox";

function BoardsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const boards = useSelector(selectAllBoards);
  const boardStatus = useSelector((state) => state.boards.status);
  const error = useSelector((state) => state.boards.error);
  const [addBoardModalOpen, setAddBoardModalOpen] = useState(false);

  useEffect(() => {
    dispatch(clearCurrentBoard());

    if (boardStatus === "idle") {
      dispatch(fetchBoards());
    }
  }, [boardStatus, dispatch]);

  const inactiveBoardExist = () =>
    boards.filter((board) => board.active === false).length > 0;

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("boardsList.title")}</h2>
        </div>
        <div>
          <Button
            size="lg"
            color="primary"
            onClick={() => setAddBoardModalOpen(true)}
            // disabled={inactiveBoardExist()}
          >
            <Icon src={plusSquare} />
            <p>{t("boardsList.button")}</p>
          </Button>
        </div>
      </Topbar>
      {inactiveBoardExist() && (
        <Alert type="warning">
          <Trans i18nKey="boardsList.inactiveBoardsPopup">
            Some boards are inactive.
            <NavLink to="/admin/subscription">Upgrade your plan</NavLink>
            to activate them all.
          </Trans>
        </Alert>
      )}
      {boardStatus === "loading" ? (
        <Loader />
      ) : boards.length !== 0 ? (
        <GridBoardList>
          {boards.map((board) => (
            <BoardCard key={board.id} boardData={board} />
          ))}
        </GridBoardList>
      ) : (
        <>
          <ContentBox style={{marginBottom: "10%"}}>
            <h3>{t("welcome.title")}</h3>
            <p style={{marginTop: "10px", marginBottom: "10px"}}>{t("welcome.message_p01")}</p>
            <p style={{marginTop: "10px", marginBottom: "10px"}}>{t("welcome.message_p02")}</p>
            <p style={{marginTop: "10px", marginBottom: "10px"}}>{t("welcome.message_p03")}</p>
            <Icon src={plusSquare} />
              <Button onClick={() => setAddBoardModalOpen(true)}>
                {t("welcome.createNewBoardBtn")}
              </Button>
          </ContentBox>
          <EmptyState
            type="noBoards"
            header={t("emptyStates.boards.header")}
            subheader={t("emptyStates.boards.subheader")}
          />
        </>
      )}
      {addBoardModalOpen && (
        <AddBoardModal
          open={addBoardModalOpen}
          handleModalClose={() => setAddBoardModalOpen(false)}
        />
      )}
    </MainContainer>
  );
}

export default BoardsList;
