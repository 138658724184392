import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  text-align: center;
  position: relative;

  > div {
    margin: 0 5px;
    width: 28px;
    height: 28px;
    background-color: var(--brand-ideolo);

    border-radius: 100%;
    animation: bouncedelay 1.4s infinite ease-in-out both;
  }

  > div:nth-child(1) {
    animation-delay: -0.32s;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    animation-delay: -0.16s;
  }

  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;
