import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 24px;
  border-radius: 4px;
  width: 100%;
  padding: 12px 16px;
  transition: all 0.6s ease;
  ${({ type }) =>
    `background-color: var(--${type}-light); color: var(--${type}-dark);`};

  p {
    position: relative;
    margin: 0 0 0 8px;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: inherit;
  }

  a {
    text-decoration: underline;
  }

  svg path {
    fill: ${({ type }) => `var(--${type}-dark)`};
  }
`;
