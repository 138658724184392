import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const GridTwoThird = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

GridTwoThird.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GridTwoThird.defaultProps = {
  children: null,
};

export default GridTwoThird;
