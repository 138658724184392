import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles.js'

const PricingTabs = ({ children }) => <Wrapper>{children}</Wrapper>

PricingTabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

PricingTabs.defaultProps = {
  children: null,
}

export default PricingTabs
