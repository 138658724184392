import React, { useState, cloneElement } from "react";
import PropTypes from "prop-types";
import { useFloating } from "@floating-ui/react-dom";
import { FloatingPortal } from "@floating-ui/react-dom-interactions";
import useOutsideClick from "hooks/useOutsideClick";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";

import { Wrapper, DropdownBox } from "./styles";
import CloseSvg from "assets/icons/close.svg";
import DotsSvg from "assets/icons/dots-vertical.svg";

const Dropdown = ({
  placement,
  closeOnClick,
  noOutsideClicker,
  trigger,
  children,
}) => {
  const { x, y, reference, floating, strategy } = useFloating({
    placement: placement,
  });
  const [isOpened, setIsOpened] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const handleTriggerClick = () => {
    isOpened ? handleClosing() : handleOpening();
  };

  const handleBoxClick = () => {
    closeOnClick && !noOutsideClicker && handleClosing();
  };

  const handleOpening = () => {
    setIsOpened(true);
    setTimeout(() => setIsAnimated(true), 0);
  };

  const handleClosing = () => {
    setIsAnimated(false);
    setTimeout(() => setIsOpened(false), 300);
  };

  const outsideClicker = useOutsideClick(handleClosing);

  const DropdownElement = () => {
    return (
      <DropdownBox
        animated={isAnimated}
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          width: "max-content",
        }}
        onClick={() => handleBoxClick()}
      >
        {children}
      </DropdownBox>
    );
  };

  return (
    <Wrapper ref={reference}>
      {trigger ? (
        cloneElement(trigger, { onClick: handleTriggerClick })
      ) : (
        <Button size="lg-square" color="white" onClick={handleTriggerClick}>
          {isOpened ? <Icon src={CloseSvg} /> : <Icon src={DotsSvg} />}
        </Button>
      )}
      {isOpened && (
        <FloatingPortal>
          {noOutsideClicker ? (
            DropdownElement()
          ) : (
            <div ref={outsideClicker}>{DropdownElement()}</div>
          )}
        </FloatingPortal>
      )}
    </Wrapper>
  );
};

Dropdown.propTypes = {
  placement: PropTypes.oneOf(["bottom-end", "bottom-start", "bottom"]),
  closeOnClick: PropTypes.bool,
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Dropdown.defaultProps = {
  placement: "bottom-end",
  closeOnClick: true,
  trigger: null,
  children: null,
};

export default Dropdown;
