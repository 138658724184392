import React from "react";
import { useParams } from "react-router-dom";
import useScript from "hooks/useScript";

import MainContainer from "layout/atoms/MainContainer";

function PublicView() {
  let params = useParams();
  useScript("https://app.ideolo.co/widget/index.js");

  return (
    <MainContainer alignCenter>
      <div
        id="ideolo-board"
        data-base-url="https://app.ideolo.co/"
        data-company-slug={params.companySlug}
        data-board-slug={params.boardSlug}
      ></div>
    </MainContainer>
  );
}

export default PublicView;
