import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Dropdown from "layout/atoms/Dropdown";
import Icon from "layout/atoms/Icon";
import Modal from "layout/atoms/Modal";
import Status from "layout/molecules/Status";
import VotesCounter from "layout/molecules/VotesCounter";

import Comments from "./Comments";
import EditMode from "./EditMode";
import Options from "./Options";

import { Wrapper, Main, ModalTop, ModalButtons } from "./styles";
import MessageSvg from "assets/icons/message-square.svg";
import EditSvg from "assets/icons/edit1.svg";
import TrashSvg from "assets/icons/trash.svg";

const IdeaCardSmall = ({
  ideaId,
  boardId,
  title,
  description,
  status,
  author,
  commentCount,
  voteCount,
  isVotedByMe,
  canEdit,
  canUpdateStatus,
  onUpdate,
}) => {
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.ideaCard",
  });
  const [currentIdeaId] = useState(ideaId);
  const [ideaDetails, setIdeaDetails] = useState({
    id: ideaId,
    title: title,
    status: status,
    voteCount: voteCount,
    isVotedByMe: isVotedByMe,
    commentCount: commentCount,
  });
  const [modalOpened, setModalOpened] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [ideaDescription, setIdeaDescription] = useState(description);
  const [ideaAuthor, setIdeaAuthor] = useState(author);

  const fetchIdea = () => {
    return api.getIdea(currentIdeaId, boardId).then((newIdeaDetails) => {
      setIdeaDetails({
        ...ideaDetails,
        newIdeaDetails,
      });
      setIdeaDescription(newIdeaDetails.description);
      setIdeaAuthor(newIdeaDetails.author);
    });
  };

  const handleUpvoteClick = async () => {
    api
      .giveIdeaUpvote(currentIdeaId, boardId)
      .then((response) => {
        setIdeaDetails({
          ...ideaDetails,
          voteCount: response.voteCount,
          isVotedByMe: true,
        });
      })
      .catch((error) => {
        console.log("Error toast");
      });
  };

  const handleDownvoteClick = () => {
    return api
      .giveIdeaDownvote(currentIdeaId, boardId)
      .then((response) => {
        setIdeaDetails({
          ...ideaDetails,
          voteCount: response.voteCount,
          isVotedByMe: false,
        });
      })
      .catch((error) => {
        console.log("Error toast");
      });
  };

  const handleTitleClick = (inModal) => {
    fetchIdea();
    !inModal && setModalOpened(!modalOpened);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSaveClick = (data) => {
    setIdeaDetails({
      ...ideaDetails,
      title: data.title,
      description: data.description,
    });
    setIdeaDescription(data.description);
  };

  const handleStatusChange = (e) => {
    return api
      .editIdeaStatus({ status: e }, ideaId, boardId)
      .then((response) => {
        setIdeaDetails({
          ...ideaDetails,
          status: response.status,
        });
      })
      .catch((error) => {
        console.log("Error toast");
      });
  };

  const handleDelete = () => {
    return api
      .deleteIdea(currentIdeaId, boardId)
      .then((response) => {
        onUpdate();
        setModalOpened(false);
      })
      .catch((error) => {
        console.log("Error toast");
        onUpdate();
        setModalOpened(false);
      });
  };

  const increaseCommentCount = () => {
    setIdeaDetails({
      ...ideaDetails,
      commentCount: ideaDetails.commentCount + 1,
    });
  };

  const handleModalClose = () => {
    setEditMode(false);
    setModalOpened(false);
  };

  const MainContent = ({ inModal = false }) => {
    return (
      <Main inModal={inModal}>
        {ideaDetails.title && !editMode && (
          <h4 onClick={() => handleTitleClick(inModal)}>{ideaDetails.title}</h4>
        )}
        {ideaDescription && !editMode && inModal && <p>{ideaDescription}</p>}
        {ideaAuthor !== null && !editMode && inModal && (
          <p>
            {t("addedBy")} <b>{ideaAuthor.displayName}</b>
          </p>
        )}

        {canEdit && editMode && (
          <EditMode
            ideaId={currentIdeaId}
            boardId={boardId}
            title={ideaDetails.title}
            description={ideaDescription}
            status={ideaDetails.status}
            canUpdateStatus={canUpdateStatus}
            onCancel={() => setEditMode(false)}
            onSave={handleSaveClick}
          />
        )}
      </Main>
    );
  };

  const OptionsButton = () => {
    return (
      <Dropdown size="sm-square" closeOnClick>
        <Button
          size="lg"
          color="white"
          disabled={editMode}
          onClick={() => setEditMode(true)}
        >
          <Icon src={EditSvg} />
          <p>{t("edit")}</p>
        </Button>
        <Button size="lg" color="white" onClick={handleDelete}>
          <Icon src={TrashSvg} />
          <p>{t("remove")}</p>
        </Button>
      </Dropdown>
    );
  };

  return (
    <ContentBox
      footer={
        <>
          <VotesCounter
            small
            votesNumber={ideaDetails.voteCount}
            isVotedByMe={ideaDetails.isVotedByMe}
            onVoteClick={
              ideaDetails.isVotedByMe ? handleDownvoteClick : handleUpvoteClick
            }
          />
          <Options>
            <Button
              size="lg-square"
              color="white"
              onClick={() => handleTitleClick()}
              disabled={commentCount === 0}
            >
              <Icon src={MessageSvg} />
              <p>{commentCount}</p>
            </Button>
            {canEdit && <OptionsButton />}
          </Options>
        </>
      }
    >
      <MainContent />
      {modalOpened && (
        <Modal open={modalOpened} onClose={handleModalClose}>
          <ContentBox>
            <ModalButtons>{canEdit && <OptionsButton />}</ModalButtons>
            <ModalTop>
              <div>
                <VotesCounter
                  votesNumber={ideaDetails.voteCount}
                  isVotedByMe={ideaDetails.isVotedByMe}
                  onVoteClick={
                    ideaDetails.isVotedByMe
                      ? handleDownvoteClick
                      : handleUpvoteClick
                  }
                />
              </div>
              <MainContent inModal />
            </ModalTop>
            <Comments
              boardId={boardId}
              ideaId={currentIdeaId}
              commentCount={ideaDetails.commentCount}
              onCommentAdded={increaseCommentCount}
            />
          </ContentBox>
        </Modal>
      )}
    </ContentBox>
  );
};

IdeaCardSmall.propTypes = {
  ideaId: PropTypes.string,
  boardId: PropTypes.string,
  voteCount: PropTypes.number,
  isVotedByMe: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  author: PropTypes.object,
  commentCount: PropTypes.number,
  canEdit: PropTypes.bool,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default IdeaCardSmall;
