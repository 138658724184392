import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";

const api = new Api();

const initialState = {
  id: "",
  name: "",
  boardSlug: "",
  color: "",
  language: "",
  status: "idle",
  ideas: {
    ideas: [],
    status: "idle",
  },
  limits: {
    cappedIdeas: 0,
  },
};

export const fetchBoardDetails = createAsyncThunk(
  "boards/fetchBoardDetails",
  async (boardId) => {
    const response = await api.getBoardDetails(boardId);

    return response;
  }
);

export const fetchBoardLimits = createAsyncThunk(
  "boards/fetchBoardLimits",
  async (boardId) => {
    const response = await api.getBoardLimits(boardId);

    return response;
  }
);

export const fetchBoardIdeas = createAsyncThunk(
  "boards/fetchBoardIdeas",
  async (boardId) => {
    const response = await api.getBoardIdeas(boardId);

    return response;
  }
);

// Modify Idea
// Change Idea Status
// Vote for Idea
// Unvote for Idea
// Update board name
// Update board language
// Update board privacy
// Update board theme
//

const currentBoardSlice = createSlice({
  name: "currentBoard",
  initialState,
  reducers: {
    updateCurrentBoard: (state, action) => {
      state.name = action.payload.name;
      state.language = action.payload.language;
      state.color = action.payload.theme.primary;
    },
    clearCurrentBoard: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBoardDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchBoardDetails.fulfilled, (state, action) => {
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.boardSlug = action.payload.boardSlug;
        state.isPublic = action.payload.isPublic;
        state.language = action.payload.language;
        state.color = action.payload.theme.primary || "53d23d";
        state.status = "succeeded";
      })
      .addCase(fetchBoardDetails.rejected, (state, action) => {
        state.id = "";
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchBoardLimits.pending, (state, action) => {
        state.limits.status = "loading";
      })
      .addCase(fetchBoardLimits.fulfilled, (state, action) => {
        state.limits = action.payload;
        state.limits.status = "succeeded";
      })
      .addCase(fetchBoardLimits.rejected, (state, action) => {
        state.limits.status = "failed";
        state.limits.error = action.error.message;
      })
      .addCase(fetchBoardIdeas.pending, (state, action) => {
        state.ideas.status = "loading";
      })
      .addCase(fetchBoardIdeas.fulfilled, (state, action) => {
        state.ideas.ideas = action.payload;
        state.ideas.status = "succeeded";
      })
      .addCase(fetchBoardIdeas.rejected, (state, action) => {
        state.ideas.status = "failed";
        state.ideas.error = action.error.message;
      });
  },
});

export const { clearCurrentBoard, updateCurrentBoard } =
  currentBoardSlice.actions;

// export const resetCurrentBoard = () => (dispatch) => {
//   setTimeout(() => {
//     dispatch(clearCurrentBoard());
//   }, 0);
// };

export const selectCurrentBoardData = (state) => state.currentBoard;

export default currentBoardSlice.reducer;
