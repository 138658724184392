import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";

import Icon from "layout/atoms/Icon";
import Dropdown from "layout/atoms/Dropdown";

import { Wrapper, Label, IconPos, Cal, Error } from "./styles";
import calendarSvg from "assets/icons/calendar.svg";

const DatePicker = ({
  type = "text",
  name,
  label,
  placeholder = "",
  defaultValue = "",
  register,
  validation,
  errors,
  setValue,
}) => {
  const { t } = useTranslation();
  const inputProps = {
    type,
    name,
    placeholder,
    defaultValue,
  };

  const displayErrors = () => {
    const message = {
      required: t("formErrors.required"),
      minLength: t("formErrors.minLength", { n: validation.minLength }),
      maxLength: t("formErrors.maxLength", { n: validation.maxLength }),
      default: errors.message,
    };

    return <Error>{message[errors.type] || message["default"]}</Error>;
  };

  const formatDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      ((date.getMonth() + 1).length !== 2
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
    );
  };

  return (
    <Wrapper hasErrors={errors}>
      <Label htmlFor={name}>{label}</Label>
      {type === "textarea" ? (
        <textarea {...inputProps} {...register(name, validation)} />
      ) : (
        <input {...inputProps} {...register(name, validation)} />
      )}
      <Dropdown
        noOutsideClicker
        placement="top"
        trigger={
          <IconPos>
            <Icon src={calendarSvg} />
          </IconPos>
        }
      >
        <Cal>
          <Calendar
            formatLongDate={(locale, date) => formatDate(date)}
            onChange={(value, event) => {
              setValue(name, formatDate(value));
            }}
          />
        </Cal>
      </Dropdown>
      {errors && displayErrors()}
    </Wrapper>
  );
};

DatePicker.propTypes = {
  type: PropTypes.oneOf([
    "email",
    "text",
    "number",
    "tel",
    "password",
    "textarea",
  ]),
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default DatePicker;
