import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";

import { Wrapper, HeartIconWrapper } from "./styles";
import HeartSvg from "assets/icons/heart.svg";
import HeartFillSvg from "assets/icons/heart-fill.svg";

const VotesCounter = ({ small, votesNumber, isVotedByMe, onVoteClick }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.ideaCard",
  });

  const handleVoteClick = () => {
    onVoteClick && onVoteClick();
  };

  return small ? (
    <Button size="lg-square" color="white">
      <HeartIconWrapper isVotedByMe={isVotedByMe}>
        <Icon src={HeartSvg} />
        <Icon src={HeartFillSvg} size="18px" />
      </HeartIconWrapper>
      <p>{votesNumber}</p>
    </Button>
  ) : (
    <Wrapper isVotedByMe={isVotedByMe} onClick={handleVoteClick}>
      <div>
        <strong>{votesNumber}</strong>
      </div>
      <Button size="sm" color="white">
        <HeartIconWrapper isVotedByMe={isVotedByMe}>
          <Icon src={HeartSvg} />
          <Icon src={HeartFillSvg} size="18px" />
        </HeartIconWrapper>
        <p>{isVotedByMe ? t("unvote") : t("vote")}</p>
      </Button>
    </Wrapper>
  );
};

VotesCounter.propTypes = {
  votesNumber: PropTypes.number,
  isVotedByMe: PropTypes.bool,
  onVoteClick: PropTypes.func,
};

export default VotesCounter;
