import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Api from "api/Api";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";
import InputText from "layout/atoms/InputText";
import Form from "layout/atoms/Form";

import { Wrapper } from "./styles";
import SaveSvg from "assets/icons/save.svg";

const EditMode = ({
  ideaId,
  boardId,
  title,
  description,
  status,
  onCancel,
  onSave,
}) => {
  const api = new Api();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: { title: title, description: description, status: status },
  });

  const handleCancel = () => {
    reset();
    onCancel && onCancel();
  };

  const handleSave = (data) => {
    return api
      .editIdea(data, ideaId, boardId)
      .then((response) => {
        onSave && onSave(data);
        onCancel && onCancel();
      })
      .catch((error) => {
        // toast
      });
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(handleSave)}>
        <InputText
          type="text"
          name="title"
          label={t("boardsDetails.forms.addIdea.fields.title.label")}
          placeholder={t(
            "boardsDetails.forms.addIdea.fields.title.placeholder"
          )}
          register={register}
          errors={errors.title}
          validation={{
            required: true,
            minLength: 6,
            maxLength: 100,
          }}
        />
        <InputText
          type="textarea"
          name="description"
          label={t("boardsDetails.forms.addIdea.fields.description.label")}
          placeholder={t(
            "boardsDetails.forms.addIdea.fields.description.placeholder"
          )}
          register={register}
          errors={errors.description}
          validation={{
            minLength: 6,
            maxLength: 500,
          }}
        />
        <div>
          <Button size="lg" color="white" onClick={handleCancel}>
            <p>{t("buttons.cancel")}</p>
          </Button>
          <Button type="submit" size="lg" color="light">
            <Icon src={SaveSvg} />
            <p>{t("buttons.save")}</p>
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

EditMode.propTypes = {
  ideaId: PropTypes.string,
  boardId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditMode;
