import React from "react";
import PropTypes from "prop-types";

import Icon from "layout/atoms/Icon";

import { Wrapper } from "./styles";

import successSvg from "assets/icons/all-done.svg";
import errorSvg from "assets/icons/close.svg";
import infoSvg from "assets/icons/info.svg";
import warningSvg from "assets/icons/warning.svg";

const Alert = ({ type, children }) => {
  const icons = {
    success: successSvg,
    error: errorSvg,
    info: infoSvg,
    warning: warningSvg,
  };

  return (
    <Wrapper type={type.toLowerCase()}>
      <Icon src={icons[type]} />
      <p>{children}</p>
    </Wrapper>
  );
};

Alert.defaultProps = {
  type: "info",
  children: null,
};

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "error", "warning", "info"]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Alert;
