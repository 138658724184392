import { useNavigate } from "react-router-dom";
export default class Api {
  async checkResponse(response) {
    if (!response.ok) {
      throw response.ok;
    }

    return response;
  }

  getHeaders(boardId) {
    const jwtToken = localStorage.getItem("authToken");
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    if (jwtToken) {
      headers.append("Authorization", "Bearer " + jwtToken);
    }

    if (boardId) {
      headers.append("x-board-id", boardId);
    }

    return headers;
  }

  async get(uri, boardId) {
    const headers = this.getHeaders(boardId);
    const apiUrl = uri;
    const response = await fetch(apiUrl, {
      headers,
      method: "GET",
      mode: "cors",
    });
    return this.checkResponse(response);
  }

  async delete(uri, boardId) {
    const headers = this.getHeaders(boardId);
    const apiUrl = uri;
    const response = await fetch(apiUrl, {
      headers,
      method: "DELETE",
      mode: "cors",
    });
    return this.checkResponse(response);
  }

  async post(uri, body = "", boardId) {
    const headers = this.getHeaders(boardId);
    const apiUrl = uri;
    const response = await fetch(apiUrl, {
      body: JSON.stringify(body),
      headers,
      method: "POST",
      mode: "cors",
    });
    return this.checkResponse(response);
  }

  async put(uri, body = "", boardId) {
    const headers = this.getHeaders(boardId);
    const apiUrl = uri;
    const response = await fetch(apiUrl, {
      body: JSON.stringify(body),
      headers,
      method: "PUT",
      mode: "cors",
    });
    return this.checkResponse(response);
  }

  async delete(uri, body = "", boardId) {
    const headers = this.getHeaders(boardId);
    const apiUrl = uri;
    const response = await fetch(apiUrl, {
      body: JSON.stringify(body),
      headers,
      method: "DELETE",
      mode: "cors",
    });
    return this.checkResponse(response);
  }

  async getJSON(uri, boardId) {
    const response = await this.get(uri, boardId);
    return response.json();
  }

  async postJSON(uri, body, boardId) {
    const response = await this.post(uri, body, boardId);
    // eslint-disable-next-line no-return-await
    return await response.json();
  }

  async putJSON(uri, body, boardId) {
    const response = await this.put(uri, body, boardId);
    // eslint-disable-next-line no-return-await
    return await response.json();
  }

  async deleteJSON(uri, body, boardId) {
    const response = await this.delete(uri, body, boardId);
    // eslint-disable-next-line no-return-await
    return await response.json();
  }

  async getPricingInfo(companyId) {
    return this.getJSON(`/api/v1/companies/${companyId}/pricing`);
  }

  async register(email, password, company, ref) {
    return this.postJSON(`/api/v1/registrations`, {
      email: email,
      password: password,
      company: company,
      ref: ref,
    });
  }

  async login(email, password) {
    return this.postJSON(`/api/v1/login`, {
      email: email,
      password: password,
    });
  }

  async logout() {
    return this.postJSON(`/api/v1/logout`);
  }

  async passwordRecoverySendLink(data) {
    const response = await this.post(
      `/api/v1/password-recovery/send-link`,
      data
    );
    // eslint-disable-next-line no-return-await
    return await response;
  }

  async passwordRecoveryCheckToken(data) {
    return this.postJSON(`/api/v1/password-recovery/check-token`, data);
  }

  async passwordRecoverySetNew(email, password, token) {
    const response = await this.post(`/api/v1/password-recovery/new-password`, {
      email: email,
      newPasswordToken: token,
      password: password,
    });
    // eslint-disable-next-line no-return-await
    return await response;
  }

  async createNewBoard(data, companyId) {
    return this.postJSON(`/api/v1/companies/${companyId}/boards`, data);
  }

  async createNewRelease(data, companyId) {
    return this.postJSON(`/api/v1/companies/${companyId}/releases`, data);
  }

  async updateRelease(data, releaseId) {
    return this.putJSON(`/api/v1/releases/${releaseId}`, data);
  }

  async deleteReleasePart(partId, releaseId) {
    return this.deleteJSON(`/api/v1/releases/${releaseId}/parts/${partId}`);
  }

  async addGenericReleasePart(data, releaseId) {
    return this.postJSON(`/api/v1/releases/${releaseId}/parts`, data);
  }

  async addIdeasAsReleaseParts(data, releaseId) {
    return this.postJSON(`/api/v1/releases/${releaseId}/add-from-ideas`, data);
  }

  async changePublishRelease(data, releaseId) {
    return this.putJSON(`/api/v1/releases/${releaseId}/publish`, data);
  }

  async getUserInfo() {
    return this.getJSON(`/api/v1/me`);
  }

  async getCompanyInfo() {
    return this.getJSON(`/api/v1/company`);
  }

  async getBoards(companyId) {
    return this.getJSON(`/api/v1/companies/${companyId}/boards`);
  }

  async getBoardDetails(boardId) {
    return this.getJSON(`/api/v1/boards/${boardId}`);
  }

  async getBoardLimits(boardId) {
    return this.getJSON(`/api/v1/boards/${boardId}/limits`);
  }

  async updateBoardName(data, boardId) {
    return this.putJSON(`/api/v1/boards/${boardId}/name`, data);
  }

  async updateBoardLanguage(data, boardId) {
    return this.putJSON(`/api/v1/boards/${boardId}/language`, data);
  }

  async updateBoardTheme(data, boardId) {
    return this.putJSON(`/api/v1/boards/${boardId}/theme`, data);
  }

  async updateBoardPrivacy(data, boardId) {
    return this.putJSON(`/api/v1/boards/${boardId}/set-public`, data);
  }

  async getBoardReleases(boardId) {
    return this.getJSON(`/api/v1/boards/${boardId}/releases`);
  }

  async getWidgetSetup(boardId) {
    return this.getJSON(`/api/v1/boards/${boardId}/widget-setup`);
  }

  async getBoards(companyId) {
    return this.getJSON(`/api/v1/companies/${companyId}/boards`);
  }

  async getBoardIdeas(boardId) {
    return this.getJSON(`/api/v1/ideas/`, boardId);
  }

  async getIdea(ideaId, boardId) {
    return this.getJSON(`/api/v1/ideas/${ideaId}`, boardId);
  }

  async createNewIdea(data, boardId) {
    return this.postJSON(`/api/v1/ideas/`, data, boardId);
  }

  async editIdea(data, ideaId, boardId) {
    return this.putJSON(`/api/v1/ideas/${ideaId}`, data, boardId);
  }

  async editIdeaStatus(data, ideaId, boardId) {
    return this.putJSON(`/api/v1/ideas/${ideaId}/status`, data, boardId);
  }

  async deleteIdea(ideaId, boardId) {
    return this.deleteJSON(`/api/v1/ideas/${ideaId}`, {}, boardId);
  }

  async giveIdeaUpvote(ideaId, boardId) {
    return this.postJSON(`/api/v1/ideas/${ideaId}/upvote`, {}, boardId);
  }

  async giveIdeaDownvote(ideaId, boardId) {
    return this.postJSON(`/api/v1/ideas/${ideaId}/downvote`, {}, boardId);
  }

  async getIdeaComments(ideaId, boardId) {
    return this.getJSON(`/api/v1/ideas/${ideaId}/comments`, boardId);
  }

  async addIdeaComment(data, ideaId, boardId) {
    return this.postJSON(`/api/v1/ideas/${ideaId}/comments`, data, boardId);
  }

  async getReleases(companyId) {
    return this.getJSON(`/api/v1/companies/${companyId}/releases`);
  }

  async getReleaseDetails(releaseId) {
    return this.getJSON(`/api/v1/releases/${releaseId}`);
  }

  async getBoardReleases(boardId) {
    return this.getJSON(`/api/v1/boards/${boardId}/releases`);
  }

  async getUsers(companyId) {
    return this.getJSON(`/api/v1/companies/${companyId}/users`);
  }

  async getCompanySubscription(companyId) {
    return this.getJSON(`/api/v1/companies/${companyId}/subscription`);
  }

  async upgradeCompanySubscription(companyId, data) {
    return this.postJSON(
      `/api/v1/companies/${companyId}/subscription/upgrade/`,
      data
    );
  }
}
