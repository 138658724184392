import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled(SVG)`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
  backface-visibility: hidden;
`;
