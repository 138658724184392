import React, { useState, useRef, useCallback, useEffect } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import useClickOutside from "hooks/useClickOutside";

import { Wrapper, Label, Indicator, Picker } from "./styles";

const ColorPicker = ({ name, label, value, onChange }) => {
  const modal = useRef();
  const [color, setColor] = useState(value);

  const handleChange = (e) => {
    onChange && onChange(name, e);
    setColor(e.substring(1));
  };

  useEffect(() => {
    value && setColor(value);
  }, [value]);

  const [opened, setOpened] = useState(false);
  const close = useCallback(() => setOpened(false), []);

  useClickOutside(modal, close);

  return (
    <Wrapper>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div onClick={() => setOpened(true)} ref={modal}>
        <Indicator color={`#${color}`}>
          <Picker active={opened}>
            <HexColorPicker color={color} onChange={handleChange} />
          </Picker>
        </Indicator>
        #<HexColorInput name={name} color={color} onChange={handleChange} />
      </div>
    </Wrapper>
  );
};

ColorPicker.propTypes = {};

export default ColorPicker;
