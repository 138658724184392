import React, { useState, useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentBoard } from "store/currentBoardSlice";
import { selectAuthInfo } from "store/authSlice";
import { addToast } from "store/toastsSlice";
import { useNavigate } from "react-router-dom";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Logo from "layout/atoms/Logo";

const ResetPasswordForm = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useSelector(selectAuthInfo);
  let api = new Api();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  const handleLogin = (data) => {
    api
      .passwordRecoverySendLink(data)
      .then((loginResponse) => {
        navigate(`/password-reset/sent`);
      })
      .catch((error) => {
        dispatch(
          addToast({
            title: "toasts.error.title",
            description: "toasts.error.description",
            type: "error",
          })
        );
      });
  };

  return authInfo.isUserLoggedIn ? (
    <Navigate to={`/`} />
  ) : (
    <MainContainer alignCenter>
      <Logo circle />
      <Form onSubmit={handleSubmit(handleLogin)}>
        <ContentBox
          size="s"
          centeredHeader
          header={<h2>{t("resetPassword.title")}</h2>}
          footer={
            <>
              <NavLink to="/login">{t("resetPassword.backToLogin")}</NavLink>
              <Button type="submit" size="lg" color="primary">
                {t("resetPassword.button")}
              </Button>
            </>
          }
        >
          <InputText
            type="text"
            name="email"
            label={t("resetPassword.emailLabel")}
            register={register}
            errors={errors.email}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 50,
            }}
          />
        </ContentBox>
      </Form>
    </MainContainer>
  );
};

export default ResetPasswordForm;
