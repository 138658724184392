import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toasts: [],
};

let id = 0;

const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addNewToast: (state, action) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
  },
});

export const { addNewToast, removeToast } = toastsSlice.actions;

export const selectAllToasts = (state) => state.toasts.toasts;

export const addToast = (options) => (dispatch, getState) => {
  const values = options
    ? options
    : {
        type: "error",
      };

  dispatch(
    addNewToast({
      ...values,
      id: id++,
    })
  );
};

export default toastsSlice.reducer;
