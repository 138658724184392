import React, { useState, useEffect } from "react";

import { Wrapper, WrapperWithBottomBar } from "./styles";

const Topbar = ({ withBottomBar, children }) => {
  const [scrollOnTop, setScrollOnTop] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const toggleMenu = () => {
    let distanceFromTop = window.pageYOffset;

    return function () {
      let newDistanceFromTop = window.pageYOffset;
      if (newDistanceFromTop < 10 || distanceFromTop >= newDistanceFromTop) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
      if (newDistanceFromTop < 5) {
        setScrollOnTop(true);
      } else {
        setScrollOnTop(false);
      }
      distanceFromTop = newDistanceFromTop;
    };
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleMenu());
    return window.removeEventListener("scroll", toggleMenu());
  });

  return withBottomBar ? (
    <WrapperWithBottomBar scrolled={!scrollOnTop} menuShowed={showMenu}>
      {children}
    </WrapperWithBottomBar>
  ) : (
    <Wrapper scrolled={!scrollOnTop} menuShowed={showMenu}>
      {children}
    </Wrapper>
  );
};

export default Topbar;
