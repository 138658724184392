import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {fetchBoardIdeas, selectCurrentBoardData,} from "store/currentBoardSlice";

import MainContainer from "layout/atoms/MainContainer";
import Button from "layout/atoms/Button";
import GridBoard from "layout/atoms/GridBoard";
import Icon from "layout/atoms/Icon";
import Loader from "layout/atoms/Loader";
import Topbar from "layout/atoms/Topbar";
import PublicSvg from "assets/icons/globe2.svg";
import CodeSvg from "assets/icons/code-download.svg";
import EmptyState from "layout/molecules/EmptyState";
import Filters from "layout/molecules/Filters";

import AddIdeaModal from "layout/organisms/AddIdeaModal";
import IdeaCard from "layout/organisms/IdeaCard";

import PlusSquareSVG from "assets/icons/plus-square.svg";
import Label from "layout/atoms/Label";
import Alert from "layout/atoms/Alert";
import Dropdown from "layout/atoms/Dropdown";
import ContentBox from "../../../../layout/atoms/ContentBox";

function BoardDetails(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const currentBoardData = useSelector(selectCurrentBoardData);
  const board = useSelector(selectCurrentBoardData);
  const [boardDetails, setBoardDetails] = useState({
    id: boardId,
  });
  const [filterName, setFilterName] = useState("ALL");
  const [sortName, setSortName] = useState("VOTES");
  const [addIdeaModalOpen, setAddIdeaModalOpen] = useState(false);

  const navigate = useNavigate();

  const openSettings = (path) => {
    navigate(`/admin/boards/${boardId}/${path}`);
  }

  const handleIdeaListUpdate = () => {
    dispatch(fetchBoardIdeas(boardId));
  };

  const cappedIdeaExist = () => currentBoardData.limits.cappedIdeas > 0;

  const statuses = [
    { name: "All", value: "ALL" },
    { name: "Unpublished", value: "NOT_PUBLIC" },
    { name: "Open", value: "OPEN" },
    { name: "Review", value: "REVIEW" },
    { name: "Planned", value: "PLANNED" },
    { name: "In Progress", value: "IN_PROGRESS" },
    { name: "Complete", value: "COMPLETE" },
  ];

  const sorts = [
    { name: "Votes", value: "VOTES" },
    { name: "Status", value: "STATUS" },
  ];

  const IdeasList = ({ ideas, filterName }) => {
    let ideasToMap =
      filterName !== "ALL"
        ? ideas.filter((idea) => idea.status === filterName)
        : ideas;

    const array = ideasToMap.slice().sort((a, b) => b.voteCount - a.voteCount);

    return ideasToMap.length !== 0 ? (
      <div>
        {array.map((idea) => (
          <IdeaCard
            key={idea.id}
            ideaId={idea.id}
            boardId={boardDetails.id}
            voteCount={idea.voteCount}
            isVotedByMe={idea.isVotedByMe}
            title={idea.title}
            description={idea.description || ""}
            status={idea.status}
            author={idea.author || null}
            commentCount={idea.commentCount}
            canEdit={idea.canEdit}
            canUpdateStatus={idea.canUpdateStatus}
            onUpdate={() => handleIdeaListUpdate()}
          />
        ))}
      </div>
    ) : (
      <>
      <ContentBox>
        <h3>{t("emptyStates.ideas.help.title")}</h3>
        <p style={{marginTop: "10px", marginBottom: "10px"}}>{t("emptyStates.ideas.help.question")}</p>
        <p style={{marginTop: "10px", marginBottom: "10px"}}>
          <Icon src={CodeSvg} />
          <Button onClick={() => openSettings("widget")}>{t("emptyStates.ideas.help.embedOption")}</Button>
        </p>
        <p style={{marginTop: "10px", marginBottom: "10px"}}>
          <Icon src={PublicSvg} />
          <Button onClick={() => openSettings("settings")}>{t("emptyStates.ideas.help.publicOption")}</Button>
        </p>
      </ContentBox>
      <EmptyState
        type="noIdeas"
        header={t("emptyStates.ideas.header")}
        subheader={t("emptyStates.ideas.subheader")}
      />
      </>
    );
  };

  return (
    <MainContainer>
      <Topbar withBottomBar>
        <div>
          <div>
            <h2>{t("boardsDetails.submenuNav.ideasList")}</h2>
          </div>
          <div>
            <Button
              size="lg"
              color="primary"
              onClick={() => setAddIdeaModalOpen(true)}
            >
              <Icon src={PlusSquareSVG} />
              <p>{t("boardsDetails.submenuNav.addNewIdea")}</p>
            </Button>
          </div>
        </div>
        <Filters
          filters={
            <Dropdown
              placement="bottom-start"
              trigger={<strong>{t(`statuses.${filterName}`)} &#9662;</strong>}
            >
              {statuses.map((item) => (
                <Label
                  key={item.value}
                  size="s"
                  status={item.value}
                  onClick={() => setFilterName(item.value)}
                >
                  {t(`statuses.${item.value}`)}
                </Label>
              ))}
            </Dropdown>
          }
          sorts={<strong>{t(`sorts.${sortName}`)}</strong>}
        />
      </Topbar>
      {cappedIdeaExist() && (
        <Alert type="warning">
          <Trans
            i18nKey="boardsDetails.hiddenIdeasPopup"
            values={{ cappedIdeas: currentBoardData.limits.cappedIdeas }}
          >
            There are
            <NavLink to="/admin/subscription">Upgrade your plan</NavLink>
            to see them
          </Trans>
        </Alert>
      )}
      <GridBoard>
        {currentBoardData.ideas.status === "loading" ? (
          <Loader />
        ) : (
          <IdeasList
            boardId={boardDetails.id}
            ideas={board.ideas.ideas}
            filterName={filterName}
            onUpdate={() => handleIdeaListUpdate()}
          />
        )}
      </GridBoard>
      {addIdeaModalOpen && (
        <AddIdeaModal
          boardId={boardDetails.id}
          open={addIdeaModalOpen}
          handleModalClose={() => setAddIdeaModalOpen(false)}
          onNewIdeaAdded={handleIdeaListUpdate}
        />
      )}
    </MainContainer>
  );
}

export default BoardDetails;
