import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  svg {
    position: relative;
  }

  &::before {
    position: absolute;
    top: 18%;
    left: 50%;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    background-color: var(--white2);
    transform: translate3d(-50%, 0, 0);
    opacity: 0.8;
    content: "";
  }
`;

export const Text = styled.div`
  position: relative;
  margin: 0 0 24px;
  padding: 0;
  text-align: center;

  h3 {
    margin: 12px 0 6px;
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
  }
`;
