import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetBoards } from "store/boardsSlice";
import { resetCompany } from "store/companySlice";
import { clearCurrentBoard } from "store/currentBoardSlice";
import { addToast } from "store/toastsSlice";
import { logoutMe, selectAuthInfo } from "store/authSlice";

import MainContainer from "layout/atoms/MainContainer";

const LogoutForm = (props) => {
  const dispatch = useDispatch();
  const authInfo = useSelector(selectAuthInfo);
  const { t } = useTranslation("translation", {
    keyPrefix: "logoutForm",
  });

  useEffect(() => {
    localStorage.removeItem("authToken");
    dispatch(resetBoards());
    dispatch(resetCompany());
    dispatch(clearCurrentBoard());
    dispatch(logoutMe());
    // setTimeout(
    //   () =>
    //     dispatch(
    //       addToast({
    //         title: "toasts.loggedOut.title",
    //         description: "toasts.loggedOut.description",
    //         type: "success",
    //       })
    //     ),
    //   1000
    // );
  }, [dispatch]);

  return !authInfo.isUserLoggedIn ? (
    <Navigate to={`/login`} />
  ) : (
    <MainContainer alignCenter>
      <h2>{t("title")}</h2>
    </MainContainer>
  );
};

export default LogoutForm;
