import React from "react";
import { Link } from "react-router-dom";

import Icon from "layout/atoms/Icon";

import { Wrapper } from "./styles";
import LogoSvg from "assets/logo.svg";

const Logo = ({ size, circle }) => {
  return (
    <Wrapper size={size} circle={circle}>
      <Link to="/">
        <Icon src={LogoSvg} size={circle ? "30px" : "50px"} />
      </Link>
    </Wrapper>
  );
};

export default Logo;
