import styled, { css } from "styled-components";

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  ${({ disabled }) => disabled && "opacity: 0.5;"}

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
`;

export const Square = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--brand);
  border-radius: 2px;
  background-color: ${({ checked }) =>
    checked ? "var(--brand)" : "rgba(0,0,0,0)"};
  transition: background-color 0.3s ease;
  will-change: background-color;

  > div {
    position: relative;
    opacity: ${({ checked }) => (checked ? "1" : "0")};
    transition: opacity 0.3s ease;
    will-change: opacity;

    > svg {
      width: 18px;
      height: 18px;

      path {
        fill: var(--white);
      }
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: ${({ checked }) => (checked ? "0" : "100%")};
      height: 100%;
      background: var(--brand);
      transition: width 0.2s 0.3s ease-in-out;
      will-change: width;
      content: "";
    }
  }
`;

export const Desc = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > * {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
