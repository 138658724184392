import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled.div`
  z-index: 6000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;

  p {
    display: none;
  }

  &::before {
    content: "";
  }

  .line {
    fill: none;
    stroke: var(--white);
    stroke-width: 6;
    transition: stroke 0.3s ease,
      stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    &2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }
    &3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      .line {
        &1 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
        &2 {
          stroke-dasharray: 1 60;
          stroke-dashoffset: -30;
          stroke-width: 6;
        }
        &3 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
      }
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;

export const MenuIcon = styled(SVG)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;
