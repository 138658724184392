import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./button.module.scss";

const Button = ({
  type = "button",
  size,
  theme,
  color,
  disabled,
  to,
  onClick,
  children,
}) => {
  const handleOnClick = (e) => {
    onClick && onClick(e);
  };

  return to ? (
    <Link
      className={`${styles.wrap} ${styles[size]} ${styles[theme]} ${styles[color]}`}
      type={type}
      to={to}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {typeof children === "string" ? <p>{children}</p> : children}
    </Link>
  ) : (
    <button
      className={`${styles.wrap} ${styles[size]} ${styles[theme]} ${styles[color]}`}
      type={type}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {typeof children === "string" ? <p>{children}</p> : children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf(["sm-square", "lg-square", "sm", "lg"]),
  theme: PropTypes.oneOf(["transparent", "bordered", "filled"]),
  color: PropTypes.oneOf([
    "white",
    "light",
    "primary",
    "success",
    "error",
    "warning",
    "info",
  ]),
  disabled: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Button.defaultProps = {
  size: "sm",
  color: "white",
  disabled: false,
  children: null,
};

export default Button;
