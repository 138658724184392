import React from "react";
import PropTypes from "prop-types";

import styles from "./header.module.scss";

const Header = ({ size, margin, color, center, children }) => {
  const classNames = `${styles.wrap} ${styles[size]} ${styles[margin]} ${
    styles[color]
  } ${center && styles.center}`;

  return {
    h1: <h1 className={classNames}>{children}</h1>,
    h2: <h2 className={classNames}>{children}</h2>,
    h3: <h3 className={classNames}>{children}</h3>,
    h4: <h4 className={classNames}>{children}</h4>,
    h5: <h5 className={classNames}>{children}</h5>,
    h6: <h6 className={classNames}>{children}</h6>,
  }[size];
};

Header.propTypes = {
  size: PropTypes.oneOf(["h2", "h3", "h4", "h5", "h6"]),
  margin: PropTypes.oneOf(["xs", "s", "m", "l", "xl"]),
  color: PropTypes.oneOf(["black", "primary", "white"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Header.defaultProps = {
  size: "h2",
  margin: "s",
  color: "black",
  children: null,
};

export default Header;
