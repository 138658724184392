import listSvg from "assets/icons/list.svg";
import mapSvg from "assets/icons/map.svg";
import rocketSvg from "assets/icons/rocket.svg";
import codeSvg from "assets/icons/code-download.svg";
import SettingsSvg from "assets/icons/settings.svg";

export const boardNavigation = (boardId) => [
  {
    id: "001",
    name: "ideasList",
    to: `/admin/boards/${boardId}/list`,
    icon: listSvg,
  },
  {
    id: "002",
    name: "roadmap",
    to: `/admin/boards/${boardId}/roadmap`,
    icon: mapSvg,
  },
  {
    id: "003",
    name: "releases",
    to: `/admin/boards/${boardId}/releases`,
    icon: rocketSvg,
  },
  {
    id: "005",
    name: "widget",
    to: `/admin/boards/${boardId}/widget`,
    icon: codeSvg,
  },
  {
    id: "006",
    name: "boardSettings",
    to: `/admin/boards/${boardId}/settings`,
    icon: SettingsSvg,
  },
];
