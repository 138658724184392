import React from "react";

import styles from "./gridRoadMap.module.scss";

const GridRoadmap = ({ children }) => {
  return (
    <div className={styles.wrap}>
      <div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default GridRoadmap;
