import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const Label = ({ size, status, block, onClick, children }) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Wrapper
      size={size}
      block={block}
      status={status.toLowerCase()}
      onClick={handleClick}
    >
      {status ? <p>{children.replace("_", " ").toLowerCase()}</p> : children}
    </Wrapper>
  );
};

Label.defaultProps = {
  size: "s",
  block: false,
  children: "",
};

Label.propTypes = {
  size: PropTypes.oneOf(["s", "m", "l"]),
  block: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.string,
};

export default Label;
