import styled, { css } from "styled-components";

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 15px;
  padding: 0;
  ${({ disabled }) => disabled && "opacity: 0.5;"}

  input {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
`;

export const Circle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--brand);
  border-radius: 50%;
  background: none;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: ${({ checked }) => (checked ? "10px" : "0")};
    height: ${({ checked }) => (checked ? "10px" : "0")};
    background: var(--brand);
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.3s ease;
    will-change: width, height;
    content: "";
  }
`;

export const Desc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 0 0 10px;
    width: 100%;

    > * {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
