import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";

const api = new Api();

const initialState = {
  boards: [],
  status: "idle",
  error: null,
};

export const fetchBoards = createAsyncThunk("boards/fetchBoards", async () => {
  const companyId = await api.getCompanyInfo().then((companyInfo) => {
    return companyInfo.companyId;
  });

  const response = await api.getBoards(companyId);

  return response;
});

// Update board name - when user change in board settings

const boardsSlice = createSlice({
  name: "boards",
  initialState,
  reducers: {
    resetBoards: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBoards.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchBoards.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.boards = action.payload.sort((x, y) =>
          x.active === y.active ? 0 : x.active ? -1 : 1
        );
      })
      .addCase(fetchBoards.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { boardAdded, boardUpdated, reactionAdded, resetBoards } =
  boardsSlice.actions;

export default boardsSlice.reducer;

export const selectAllBoards = (state) => state.boards.boards;

export const selectBoardById = (state, boardId) =>
  state.boards.boards.find((board) => board.id === boardId);
