import styled, { css } from "styled-components";

export const Wrapper = styled.p`
  text-align: ${({ center }) => (center ? "center" : "left")};

  a {
    ${({ underline }) =>
      underline
        ? "text-decoration: underline;"
        : css`
            color: var(--brand);
            font-weight: 600;
          `}
  }
`;
