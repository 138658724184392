import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectCompanyInfo } from "store/companySlice";
import {
  fetchBoardDetails,
  fetchBoardIdeas,
  selectCurrentBoardData,
} from "store/currentBoardSlice";
import { fetchReleases, selectAllReleases } from "store/releasesSlice";
import Filters from "layout/molecules/Filters";
import EmptyState from "layout/molecules/EmptyState";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";

import Dropdown from "layout/atoms/Dropdown";
import Button from "layout/atoms/Button";
import Label from "layout/atoms/Label";
import ContentBox from "layout/atoms/ContentBox";
import GridBoard from "layout/atoms/GridBoard";
import Icon from "layout/atoms/Icon";
import Topbar from "layout/atoms/Topbar";
import ReleaseCard from "layout/organisms/ReleaseCard";
import AddReleaseModal from "layout/organisms/AddReleaseModal";

import PlusSquareSVG from "assets/icons/plus-square.svg";

function BoardReleases() {
  const dispatch = useDispatch();
  const companyInfo = useSelector(selectCompanyInfo);
  const currentBoardData = useSelector(selectCurrentBoardData);
  const releases = useSelector(selectAllReleases);
  const releaseStatus = useSelector((state) => state.releases.status);
  const [filterName, setFilterName] = useState("ALL");
  const [sortName, setSortName] = useState("DATE");
  const error = useSelector((state) => state.releases.error);

  const api = new Api();
  const { t } = useTranslation();
  const [addReleaseModalOpen, setAddReleaseModalOpen] = useState(false);

  useEffect(() => {
    if (releaseStatus === "idle") {
      dispatch(fetchReleases(currentBoardData.id));
    }
  }, [releaseStatus, dispatch]);

  const filters = [
    { trans: "statuses.ALL", value: "ALL" },
    { trans: "boardsDetails.releaseCard.published", value: "published" },
    { trans: "boardsDetails.releaseCard.unpublished", value: "unpublished" },
  ];

  let releasesToMap = {
    ALL: releases,
    published: releases.filter((release) => release.isPublished === true),
    unpublished: releases.filter((release) => release.isPublished === false),
  };

  return (
    <MainContainer>
      <Topbar withBottomBar>
        <div>
          <div>
            <h2>{t("boardsDetails.releases.header")}</h2>
          </div>
          <div>
            <Button
              size="lg"
              color="primary"
              onClick={() => setAddReleaseModalOpen(true)}
            >
              <Icon src={PlusSquareSVG} />
              <p>{t("boardsDetails.forms.addRelease.header")}</p>
            </Button>
          </div>
        </div>
        <Filters
          filters={
            <Dropdown
              placement="bottom-start"
              trigger={
                <strong>
                  {t(
                    `${
                      filterName !== "ALL"
                        ? `boardsDetails.releaseCard.${filterName}`
                        : "statuses.ALL"
                    }`
                  )}{" "}
                  &#9662;
                </strong>
              }
            >
              {filters.map((item) => (
                <Label
                  key={item.value}
                  size="s"
                  status={item.value}
                  onClick={() => setFilterName(item.value)}
                >
                  {t(`${item.trans}`)}
                </Label>
              ))}
            </Dropdown>
          }
          sorts={<strong>{t(`sorts.${sortName}`)}</strong>}
        />
      </Topbar>
      <GridBoard>
        {releasesToMap[filterName].length !== 0 ? (
          releasesToMap[filterName].map((item) => (
            <ReleaseCard
              key={item.id}
              releaseId={item.id}
              boardId={currentBoardData.id}
              name={item.name}
              releaseDate={item.releaseDate}
            />
          ))
        ) : (
          <EmptyState
            type="noReleases"
            header={t("emptyStates.releases.header")}
            subheader={t("emptyStates.releases.subheader")}
          />
        )}
      </GridBoard>
      {addReleaseModalOpen && (
        <AddReleaseModal
          boardId={currentBoardData.id}
          companyId={companyInfo.id}
          open={addReleaseModalOpen}
          handleModalClose={() => setAddReleaseModalOpen(false)}
          onNewReleaseAdded={() => {}}
        />
      )}
    </MainContainer>
  );
}

export default BoardReleases;
