import styled from "styled-components";
import ContentBox from "layout/atoms/ContentBox";

export const Wrapper = styled(ContentBox)`
  position: relative;
  overflow: hidden;
  min-height: 128px;
  box-shadow: ${({ theme }) => theme.boxShadows.level1};
  ${({ active }) => !active && "background: rgba(0,0,0,.01);"}

  > header,
  > main {
    position: relative;
  }

  h3 {
    margin: 0 0 ${({ active }) => (active ? "22px" : "4px")};
  }

  &::before {
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    background-color: #${({ color }) => color};
    transform: translate3d(50%, 0, 0);
    transform-origin: bottom left;
    transition: width 0.6s ease;
    will-change: width;
    content: "";
  }

  a {
    display: inline-block;
    color: #${({ color }) => color};
    transition: all 0.3s ease;
    will-change: color;
  }

  > footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    opacity: 0;
    transition: opacity 0.3s ease;

    p {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--white);
    }

    svg {
      position: absolute;
      bottom: 100%;
      right: 0;
      width: 30px;
      height: auto;
      transform: translate3d(-10px, 0, 0);
      transition: transform 0.6s ease;
      will-change: transform;

      path {
        fill: var(--white);
      }
    }
  }

  &:hover {
    ::before {
      width: 200px;
    }

    > footer {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      will-change: transform, width;

      svg {
        transform: translate3d(0, 0, 0);
      }
    }
  }
`;
