import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addToast } from "store/toastsSlice";
import { useNavigate, useParams } from "react-router-dom";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import Loader from "layout/atoms/Loader";

const ResetPasswordToken = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  const dispatch = useDispatch();
  let api = new Api();
  const { t } = useTranslation();

  const checkToken = () => {
    api
      .passwordRecoveryCheckToken({
        email: params.email,
        token: params.token,
      })
      .then((response) => {
        navigate(
          `/password-reset/new/${params.email}/${response.newPasswordToken}`
        );
      })
      .catch((error) => {
        navigate(`/login`);
        dispatch(
          addToast({
            title: "toasts.error.title",
            description: "toasts.error.description",
            type: "error",
          })
        );
      });
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <MainContainer alignCenter>
      <Loader />
    </MainContainer>
  );
};

export default ResetPasswordToken;
