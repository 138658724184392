import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Avatar, Details } from "./styles";

import SlowConnectionSvg from "assets/illustrations/slow-connection.svg";
import NotFoundSvg from "assets/illustrations/page-not-found.svg";
import AvatarImg from "assets/illustrations/avatar.png";
import Label from "layout/atoms/Label";

const UserCard = ({ userData }) => {
  return (
    <Wrapper key={userData.id}>
      <Avatar src={userData.photoUrl || AvatarImg} />
      <Details>
        <h4>{userData.displayName || "John Doe"}</h4>
        <div>
          {userData.kind && (
            <Label status={userData.kind === "SSO" ? "OPEN" : "COMPLETE"}>
              {userData.kind}
            </Label>
          )}
        </div>
      </Details>
    </Wrapper>
  );
};

UserCard.propTypes = {
  userData: PropTypes.object,
};

export default UserCard;
