import React from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import MainContainer from "layout/atoms/MainContainer";
// import Topbar from "layout/atoms/Topbar";
// import ContentBox from "layout/atoms/ContentBox";
// import Button from "layout/atoms/Button";

const Dashboard = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Navigate to={`/admin/boards`} />
    // <MainContainer>
    //   <Topbar>
    //     <h2>{t("dashboard.title")}</h2>
    //   </Topbar>
    // <ContentBox> */}
    // <h3>{t("dashboard.header")}</h3> */}
    // <Button
    //     size="lg"
    //     color="primary"
    //     onClick={() => i18n.changeLanguage("en")}
    //   >
    //     English
    //   </Button>
    //   <br />
    //   <br />
    //   <Button
    //     size="lg"
    //     color="light"
    //     onClick={() => i18n.changeLanguage("pl")}
    //   >
    //     Polski
    //   </Button>
    // </ContentBox>
    // </MainContainer>
  );
};

export default Dashboard;
