import React from "react";
import PropTypes from "prop-types";

import {Footer, Header, Main, Wrapper} from "./styles";

const ContentBox = ({
  size,
  centeredHeader,
  bgColor,
  onClick,
  header,
  footer,
  className,
  children,
  style,
}) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Wrapper
      size={size}
      bgColor={bgColor}
      clickable={onClick ? true : false}
      onClick={handleClick}
      className={className}
      style={style}
    >
      {header && <Header centeredHeader={centeredHeader}>{header}</Header>}
      <Main>{children}</Main>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

ContentBox.propTypes = {
  size: PropTypes.string,
  centeredHeader: PropTypes.bool,
  bgColor: PropTypes.oneOf(["white", "gradient"]),
  onClick: PropTypes.func,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ContentBox.defaultProps = {
  centeredHeader: false,
  gradientBg: "white",
  header: null,
  footer: null,
  children: null,
};

export default ContentBox;
