import React from "react";
import PropTypes from "prop-types";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";

import { Wrapper, Text } from "./styles";
import BoardsSvg from "assets/illustrations/empty-state-boards.svg";
import IdeasSvg from "assets/illustrations/empty-state-ideas.svg";
import ReleasesSvg from "assets/illustrations/empty-state-releases.svg";
import UsersSvg from "assets/illustrations/empty-state-users.svg";
import NotFoundSvg from "assets/illustrations/empty-state-not-found.svg";

const EmptyState = ({ type, header, subheader, button }) => {
  const icons = {
    noBoards: BoardsSvg,
    noIdeas: IdeasSvg,
    noReleases: ReleasesSvg,
    noUsers: UsersSvg,
    notFound: NotFoundSvg,
  };

  return (
    <Wrapper>
      <Icon src={icons[type]} size="200px" />
      <Text>
        <h3>{header}</h3>
        <h4>{subheader}</h4>
      </Text>
      {type === "notFound" && (
        <Button to="/" color="primary" size="lg">
          {button}
        </Button>
      )}
    </Wrapper>
  );
};

EmptyState.propTypes = {
  type: PropTypes.oneOf([
    "noBoards",
    "noIdeas",
    "noReleases",
    "noUsers",
    "notFound",
  ]),
  header: PropTypes.string,
  subheader: PropTypes.string,
  button: PropTypes.string,
};

export default EmptyState;
