import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBoardDetails,
  fetchBoardIdeas,
  selectCurrentBoardData,
} from "store/currentBoardSlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import { useTranslation } from "react-i18next";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";
import Topbar from "layout/atoms/Topbar";
import GridRoadmap from "layout/atoms/GridRoadmap";
import ContentBox from "layout/atoms/ContentBox";
import EmptyState from "layout/molecules/EmptyState";
import Loader from "layout/atoms/Loader";
import Label from "layout/atoms/Label";

import MessageSvg from "assets/icons/message-square.svg";
import HeartSvg from "assets/icons/heart.svg";
import IdeaCardSmall from "layout/organisms/IdeaCardSmall";

function BoardRoadmap() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentBoardData = useSelector(selectCurrentBoardData);

  const handleIdeaListUpdate = () => {
    dispatch(fetchBoardIdeas(currentBoardData.id));
  };

  const IdeasList = () => {
    return currentBoardData.ideas.ideas.length !== 0 ? (
      <GridRoadmap>
        <div>
          <Label status="REVIEW">{t(`statuses.REVIEW`)}</Label>
          {currentBoardData.ideas.ideas
            .filter((a) => a.status === "REVIEW")
            .sort((a, b) => b.commentCount - a.commentCount)
            .map((idea) => (
              <IdeaCardSmall
                key={idea.id}
                ideaId={idea.id}
                boardId={currentBoardData.id}
                voteCount={idea.voteCount}
                isVotedByMe={idea.isVotedByMe}
                title={idea.title}
                description={idea.description || ""}
                status={idea.status}
                author={idea.author || null}
                commentCount={idea.commentCount}
                canEdit={idea.canEdit}
                canUpdateStatus={idea.canUpdateStatus}
                onUpdate={() => handleIdeaListUpdate()}
              />
            ))}
        </div>
        <div>
          <Label status="PLANNED">{t(`statuses.PLANNED`)}</Label>
          {currentBoardData.ideas.ideas
            .filter((a) => a.status === "PLANNED")
            .sort((a, b) => b.commentCount - a.commentCount)
            .map((idea) => (
              <IdeaCardSmall
                key={idea.id}
                ideaId={idea.id}
                boardId={currentBoardData.id}
                voteCount={idea.voteCount}
                isVotedByMe={idea.isVotedByMe}
                title={idea.title}
                description={idea.description || ""}
                status={idea.status}
                author={idea.author || null}
                commentCount={idea.commentCount}
                canEdit={idea.canEdit}
                canUpdateStatus={idea.canUpdateStatus}
                onUpdate={() => handleIdeaListUpdate()}
              />
            ))}
        </div>
        <div>
          <Label status="IN_PROGRESS">{t(`statuses.IN_PROGRESS`)}</Label>
          {currentBoardData.ideas.ideas
            .filter((a) => a.status === "IN_PROGRESS")
            .sort((a, b) => b.commentCount - a.commentCount)
            .map((idea) => (
              <IdeaCardSmall
                key={idea.id}
                ideaId={idea.id}
                boardId={currentBoardData.id}
                voteCount={idea.voteCount}
                isVotedByMe={idea.isVotedByMe}
                title={idea.title}
                description={idea.description || ""}
                status={idea.status}
                author={idea.author || null}
                commentCount={idea.commentCount}
                canEdit={idea.canEdit}
                canUpdateStatus={idea.canUpdateStatus}
                onUpdate={() => handleIdeaListUpdate()}
              />
            ))}
        </div>
        <div>
          <Label status="COMPLETE">{t(`statuses.COMPLETE`)}</Label>
          {currentBoardData.ideas.ideas
            .filter((a) => a.status === "COMPLETE")
            .sort((a, b) => b.commentCount - a.commentCount)
            .map((idea) => (
              <IdeaCardSmall
                key={idea.id}
                ideaId={idea.id}
                boardId={currentBoardData.id}
                voteCount={idea.voteCount}
                isVotedByMe={idea.isVotedByMe}
                title={idea.title}
                description={idea.description || ""}
                status={idea.status}
                author={idea.author || null}
                commentCount={idea.commentCount}
                canEdit={idea.canEdit}
                canUpdateStatus={idea.canUpdateStatus}
                onUpdate={() => handleIdeaListUpdate()}
              />
            ))}
        </div>
      </GridRoadmap>
    ) : (
      <EmptyState
        type="noIdeas"
        header={t("emptyStates.ideas.header")}
        subheader={t("emptyStates.ideas.subheader")}
      />
    );
  };

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("boardsDetails.roadmap.header")}</h2>
        </div>
      </Topbar>
      {currentBoardData.ideas.status === "loading" ? <Loader /> : <IdeasList />}
    </MainContainer>
  );
}

export default BoardRoadmap;
