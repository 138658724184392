import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 30px 0 0;

  > h4 {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  blockquote {
    position: relative;
    display: block;
    margin: 0 0 12px;
    border: 0;
    padding: 8px 14px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      background-color: var(--brand);
      opacity: 0.05;
      content: "";
    }

    > h5 {
      margin: 0 0 6px;
      font-size: 14px;
      font-weight: 600;

      span {
        font-size: 12px;
        opacity: 0.5;
        font-weight: 400;
      }
    }

    p {
      position: relative;
      font-size: 14px;
    }
  }
`;
