import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Icon from "layout/atoms/Icon/";

import { Header, NavItem, NavList, Wrapper } from "./styles";

const Submenu = ({ isActive, boardName, navItems }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.submenuNav",
  });

  return (
    <Wrapper active={isActive}>
      <Header data-letter={boardName && boardName.charAt(0)}>
        <h2>{boardName && boardName}</h2>
      </Header>
      <NavList>
        {navItems.map((item) => (
          <NavItem key={item.id}>
            {item.to.startsWith("https://") ? (
              <a href={item.to} rel="noopener noreferrer">
                <Icon src={item.icon} />
                <p>{t(item.name)}</p>
              </a>
            ) : (
              <NavLink to={item.to}>
                {/* <NavLink to={item.to} activeClassName="active"> */}
                <Icon src={item.icon} />
                <p>{t(item.name)}</p>
              </NavLink>
            )}
          </NavItem>
        ))}
      </NavList>
    </Wrapper>
  );
};

export default Submenu;
