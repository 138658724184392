import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectAuthInfo } from "store/authSlice";

import MainContainer from "layout/atoms/MainContainer";
import Loader from "layout/atoms/Loader";

const Index = () => {
  const authInfo = useSelector(selectAuthInfo);
  const { t } = useTranslation("translation");

  return {
    idle: (
      <MainContainer alignCenter>
        <Loader />
      </MainContainer>
    ),
    loading: (
      <MainContainer alignCenter>
        <Loader />
      </MainContainer>
    ),
    succeeded: <Navigate to={`/admin/dashboard`} />,
    error: <Navigate to={`/login`} />,
  }[authInfo.status];
};

export default Index;
