import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-template-columns: 2fr 1fr;
    padding-bottom: 0;
  }
`;
