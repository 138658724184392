import GridSvg from "assets/icons/grid.svg";
import UsersSvg from "assets/icons/persones.svg";
import SubscriptionSvg from "assets/icons/credit-card.svg";
import SettingsSvg from "assets/icons/settings.svg";
import PowerSvg from "assets/icons/power.svg";

export const mainNavigation = [
  {
    id: "001",
    name: "boardsList",
    to: "/admin/boards",
    icon: GridSvg,
    top: true,
  },
  {
    id: "002",
    name: "usersList",
    to: "/admin/users",
    icon: UsersSvg,
    top: true,
  },
  {
    id: "003",
    name: "subscription",
    to: "/admin/subscription",
    icon: SubscriptionSvg,
    top: true,
  },
  {
    id: "004",
    name: "settings",
    to: "/admin/settings",
    icon: SettingsSvg,
    top: true,
  },
  // {
  //   id: "005",
  //   name: "ideoloBoard",
  //   to: "https://app.ideolo.co/ideolo/feature-requests",
  //   icon: LogoSvg,
  //   top: false,
  // },
  {
    id: "006",
    name: "logOut",
    to: "/logout",
    icon: PowerSvg,
    top: false,
  },
];
