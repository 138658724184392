import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: ${({ theme }) => theme.zIndices.sticky};
  position: sticky;
  top: 70px;
  right: 0;
  left: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  transition: transform 0.6s ease;
  will-change: transform;
  transform: translate3d(
    0,
    ${({ menuShowed }) => (menuShowed ? "0" : "-100%")},
    0
  );

  &::before {
    z-index: 0;
    position: absolute;
    top: 0;
    right: -20px;
    left: -20px;
    height: 100%;
    background-color: var(--white);
    opacity: ${({ scrolled }) => (scrolled ? "1" : "0")};
    content: "";
    box-shadow: ${({ theme }) => theme.boxShadows.level3};
    transition: opacity 0.6s ease;
    will-change: opacity;
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }
  }

  > div:first-child {
    flex-direction: column;
  }

  h2 {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: 0;
    left: 0;

    &::before {
      right: -40px;
      left: -40px;
    }

    > div:first-child {
      flex-direction: row;
    }
  }
`;

export const WrapperWithBottomBar = styled.div`
  z-index: ${({ theme }) => theme.zIndices.sticky};
  position: sticky;
  top: 70px;
  right: 0;
  left: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  transition: transform 0.6s ease;
  will-change: transform;
  transform: translate3d(
    0,
    ${({ menuShowed }) => (menuShowed ? "0" : "-100%")},
    0
  );

  &::before {
    z-index: 0;
    position: absolute;
    top: 0;
    right: -20px;
    left: -20px;
    height: 100%;
    background-color: var(--white);
    opacity: ${({ scrolled }) => (scrolled ? "1" : "0")};
    content: "";
    box-shadow: ${({ theme }) => theme.boxShadows.level3};
    transition: opacity 0.6s ease;
    will-change: opacity;
  }

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 96px;
  }

  > div:last-child {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 46px;
  }

  > div:first-child > div {
    position: relative;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }
  }

  > div:first-child > div:first-child {
    flex-direction: column;
  }

  h2 {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: 0;
    left: 0;

    &::before {
      right: -40px;
      left: -40px;
    }

    > div:first-child > div:first-child {
      flex-direction: row;
    }
  }
`;
