import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: ${({ hide }) => (hide ? "none" : "flex")};
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-color: ${({ picked }) =>
    picked ? "var(--brand)" : "rgba(0, 0, 0, 0.05)"};
  border-radius: 6px;
  padding: 48px 24px;
  ${({ readOnly }) => !readOnly && "cursor: pointer;"}
`;

export const Radio = styled.div`
  ${({ readOnly }) => readOnly && "display: none;"}
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    opacity: ${({ picked }) => (picked ? "1" : "0")};
    transform: translate3d(-50%, -50%, 0);
    background-color: var(--brand);
    transition: all 0.3s ease;
    content: "";
  }
`;

export const Top = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 24px;

  > strong {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    ${({ center }) => center && "text-align: center;"}
    color: var(--brand);
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  > h3 {
    margin: 0 0 12px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    ${({ center }) => center && "text-align: center;"}
  }

  > p {
    margin: 0 0 20px;
    max-width: 500px;
    font-size: 14px;
    ${({ center }) => center && "text-align: center;"}

    a {
      color: var(--brand-500);

      svg {
        position: relative;
        top: -2px;
        width: 20px;
        margin-right: 5px;
        display: inline-block;

        path {
          fill: var(--brand-500);
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 150px;
`;

export const Price = styled.div`
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: right;
  text-transform: uppercase;
  ${({ discount }) =>
    discount
      ? css`
          font-size: 16px;
          color: var(--gray);

          &::after {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--gray);
            content: "";
          }

          > span {
            font-size: 10px;
          }
        `
      : css`
          font-size: 24px;
          color: var(--brand);
          background: linear-gradient(
            -45deg,
            rgba(112, 245, 112, 1) 0%,
            rgba(82, 209, 57, 1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          > span {
            font-size: 12px;
          }
        `}
`;
