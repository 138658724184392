import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Circle, Desc } from "./styles";

const InputRadio = ({ checked, disabled, onChange, children }) => {
  const handleClick = (e) => {
    onChange && onChange(e);
  };

  return (
    <Wrapper disabled={disabled}>
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={handleClick}
      />
      <Circle checked={checked} />
      <Desc>{children}</Desc>
    </Wrapper>
  );
};

InputRadio.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

InputRadio.defaultProps = {
  children: null,
};

export default InputRadio;
