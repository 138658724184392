import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  selectCurrentBoardData,
  updateCurrentBoard,
} from "store/currentBoardSlice";
import { selectCompanyInfo } from "store/companySlice";
import { addToast } from "store/toastsSlice";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import GridTwoThird from "layout/atoms/GridTwoThird";
import Icon from "layout/atoms/Icon";
import InputRadio from "layout/atoms/InputRadio";
import InputText from "layout/atoms/InputText";
import MainContainer from "layout/atoms/MainContainer";
import Select from "layout/atoms/Select";
import Separator from "layout/atoms/Separator";
import Topbar from "layout/atoms/Topbar";
import Header from "layout/atoms/Header";

import { supportedLangsLong } from "i18n";
import ColorPicker from "layout/atoms/ColorPicker";

import PrivateSvg from "assets/icons/lock.svg";
import PublicSvg from "assets/icons/globe2.svg";

function BoardSettings() {
  const dispatch = useDispatch();
  const currentBoardData = useSelector(selectCurrentBoardData);
  const companyInfo = useSelector(selectCompanyInfo);
  const api = new Api();
  const { t } = useTranslation();
  const [boardDetails, setBoardDetails] = useState({
    id: currentBoardData.id,
  });
  const [color, setColor] = useState(currentBoardData.color);
  const [isPublic, setIsPublic] = useState(currentBoardData.isPublic);
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: boardDetails.name,
      language: boardDetails.language,
      color: boardDetails.color,
      slug: `https://app.ideolo.co/${companyInfo.name
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")}/${currentBoardData.boardSlug}`,
      isPublic: boardDetails.isPublic,
    },
  });

  useEffect(() => {
    setBoardDetails(currentBoardData);
    setValue("name", currentBoardData.name);
    setValue("language", currentBoardData.language);
  }, [dispatch, currentBoardData]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
    }
  }, [formState, isSubmitSuccessful, reset]);

  const handleCancelButton = () => {
    reset({
      name: boardDetails.name,
      language: boardDetails.language,
    });
  };

  const handleSave = (data, e) => {
    api
      .updateBoardName({ name: data.name }, currentBoardData.id)
      .then((response) => {
        dispatch(
          addToast({
            title: "toasts.dataSaved.title",
            description: "toasts.dataSaved.description",
            type: "success",
          })
        );
        dispatch(
          updateCurrentBoard({
            ...data,
            theme: {
              primary: color,
              primaryText: "ffffff",
              text: "000000",
              background: "ffffff",
            },
          })
        );
      })
      .catch((error) => {});
    api
      .updateBoardLanguage({ language: data.language }, currentBoardData.id)
      .then((response) => {})
      .catch((error) => {});
    api
      .updateBoardTheme(
        {
          primary: color,
          primaryText: "ffffff",
          text: "000000",
          background: "ffffff",
        },
        currentBoardData.id
      )
      .then((response) => {})
      .catch((error) => {});
    api
      .updateBoardPrivacy({ isPublic: isPublic }, currentBoardData.id)
      .then((response) => {})
      .catch((error) => {});
  };

  const handleError = (data, e) => {
    dispatch(
      addToast({
        title: "toasts.error.title",
        description: "toasts.error.description",
        type: "error",
      })
    );
  };

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("boardsDetails.settings.header")}</h2>
        </div>
      </Topbar>
      <GridTwoThird>
        <ContentBox>
          <Form onSubmit={handleSubmit(handleSave, handleError)}>
            <Header size="h3" margin="m">
              General
            </Header>
            <InputText
              type="text"
              name="name"
              label={t("boardsDetails.forms.settings.fields.name.label")}
              register={register}
              errors={errors.name}
              validation={{
                required: true,
                minLength: 6,
                maxLength: 100,
              }}
            />
            <Select
              name="language"
              label={t("boardsDetails.forms.settings.fields.language.label")}
              register={register}
              errors={errors.language}
              options={supportedLangsLong}
            />
            <Separator />
            <Header size="h3" margin="m">
              Visibility
            </Header>
            <InputRadio
              name="isPublic"
              checked={!isPublic}
              onChange={() => setIsPublic(false)}
            >
              <Icon src={PrivateSvg} />
              <div>
                <h5>
                  {t("boardsDetails.forms.settings.fields.isPublic.private")}
                </h5>
                <p>
                  {t(
                    "boardsDetails.forms.settings.fields.isPublic.privateDesc"
                  )}
                </p>
              </div>
            </InputRadio>
            <InputRadio
              name="isPublic"
              checked={isPublic}
              onChange={() => setIsPublic(true)}
            >
              <Icon src={PublicSvg} />
              <div>
                <h5>
                  {t("boardsDetails.forms.settings.fields.isPublic.public")}
                </h5>
                <p>
                  {t("boardsDetails.forms.settings.fields.isPublic.publicDesc")}
                </p>
              </div>
            </InputRadio>
            {isPublic && (
              <InputText
                type="text"
                name="slug"
                disabled
                label={t("boardsDetails.forms.settings.fields.slug.label")}
                register={register}
                errors={errors.slug}
                validation={{
                  required: true,
                  minLength: 6,
                  maxLength: 100,
                }}
              />
            )}
            <Separator />
            <Header size="h3" margin="m">
              Theme
            </Header>
            <ColorPicker
              label={t("boardsDetails.forms.settings.fields.color.label")}
              value={currentBoardData.color}
              onChange={(data, color) => setColor(color.substring(1))}
            />
            <div>
              <Button
                type="button"
                size="lg"
                color="white"
                onClick={handleCancelButton}
              >
                {t("buttons.cancel")}
              </Button>
              <Button type="submit" size="lg" color="primary">
                {t("buttons.save")}
              </Button>
            </div>
          </Form>
        </ContentBox>
      </GridTwoThird>
    </MainContainer>
  );
}

export default BoardSettings;
