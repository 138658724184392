import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {clearCurrentBoard} from "store/currentBoardSlice";
import {fetchCompanyPricing, fetchCompanySubscription, selectCompanyInfo,} from "store/companySlice";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import GridTwoThird from "layout/atoms/GridTwoThird";
import MainContainer from "layout/atoms/MainContainer";
import Topbar from "layout/atoms/Topbar";
import Header from "layout/atoms/Header";
import Icon from "layout/atoms/Icon";
import Dropdown from "layout/atoms/Dropdown";
import Filters from "layout/molecules/Filters";
import Label from "layout/atoms/Label";
import Link from "layout/atoms/Link";

import PriceBox from "layout/atoms/PriceBox";
import PricingTabs from "layout/molecules/PricingTabs";

import CheckoutModal from "layout/organisms/CheckoutModal";
import GridBoardList from "layout/atoms/GridBoardList";
import InvoiceSvg from "assets/icons/file-text.svg";

function Subscription(props) {
  const api = new Api();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companyInfo = useSelector(selectCompanyInfo);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [paymentPeriod, setPaymentPeriod] = useState(1);
  const [pickedPlan, setPickedPlan] = useState(0);

  const paypalRequest = (options) => {
    return api
      .upgradeCompanySubscription(companyInfo.id, options)
      .then((response) => {
        response.link && window.location.replace(response.link);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  useEffect(() => {
    dispatch(clearCurrentBoard());
    companyInfo.id && dispatch(fetchCompanySubscription(companyInfo.id));
    companyInfo.id && dispatch(fetchCompanyPricing(companyInfo.id));
  }, [dispatch, companyInfo.id]);

  const handlePlanClick = (plan) => {
    setPickedPlan({ product: plan.product, ...plan.options[paymentPeriod] });
  };

  const handlePayClick = () => {
    paypalRequest({
      product: pickedPlan.product,
      channel: "PAYPAL",
      amount: pickedPlan.discountedPrice
        ? pickedPlan.discountedPrice.amount
        : pickedPlan.price.amount,
      months: pickedPlan.months,
    });
  };

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("subscription.header")}</h2>
        </div>
      </Topbar>
      <GridTwoThird>
        <div>
          {companyInfo.subscription && (
            <ContentBox>
              <Header size="h2" margin="m">
                {t(`subscription.currentPlan.header`)}:{" "}
                <span>
                  {t(`subscription.${companyInfo.subscription.product}.header`)}
                </span>
              </Header>
              <br />
              <GridBoardList>
                {companyInfo.subscription.product !== "FREE" && (
                  <Header size="h5" margin="xs">
                    Valid until:{" "}
                    <span>
                      {new Date(companyInfo.subscription.expiresAt)
                        .toISOString()
                        .split("T")[0]
                        .replace(/-/g, "/")}
                    </span>
                  </Header>
                )}
                <Header size="h5" margin="xs">
                  Active boards:{" "}
                  <span>
                    {companyInfo.subscription.usage.activeBoards.cap < 500
                      ? `${companyInfo.subscription.usage.activeBoards.used} / ${companyInfo.subscription.usage.activeBoards.cap}`
                      : "∞"}
                  </span>
                </Header>
                <Header size="h5" margin="xs">
                  Ideas:{" "}
                  <span>
                    {companyInfo.subscription.usage.ideas
                      ? `${companyInfo.subscription.usage.ideas.used} / ${companyInfo.subscription.usage.ideas.cap}`
                      : "∞"}
                  </span>
                </Header>
              </GridBoardList>
            </ContentBox>
          )}
          <ContentBox
            footer={
              <>
                <Link underline>
                  <a
                    href="https://ideolo.co/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("subscription.fullComparison")}
                  </a>
                </Link>
                <Button
                  type="submit"
                  size="lg"
                  color="primary"
                  disabled={pickedPlan === 0}
                  onClick={() => setCheckoutModalOpen(true)}
                >
                  {t("buttons.continue")}
                </Button>
              </>
            }
          >
            <Header size="h2" margin="m">
              {t(`subscription.pickPlan.header`)}
            </Header>
            <Filters
              billed={
                <Dropdown
                  placement="bottom-start"
                  trigger={
                    <strong>
                      {paymentPeriod === 0 ? t("monthly") : t("yearly")} &#9662;
                    </strong>
                  }
                >
                  <Label
                    size="s"
                    status={t("monthly")}
                    onClick={() => {
                      setPickedPlan(0);
                      setPaymentPeriod(0);
                    }}
                  >
                    {t(`statuses.monthly`)}
                  </Label>
                  <Label
                    size="s"
                    status={t("yearly")}
                    onClick={() => {
                      setPickedPlan(0);
                      setPaymentPeriod(1);
                    }}
                  >
                    {t(`statuses.yearly`)}
                  </Label>
                </Dropdown>
              }
            />
            <br />
            {companyInfo.pricing &&
              companyInfo.pricing[0].options[paymentPeriod].discountedPrice && (
                <>
                  <Label size="s" status="complete">
                    {t(`subscription.discountCode`)}
                  </Label>
                  &nbsp;&nbsp;
                </>
              )}

            {paymentPeriod === 1 ? (
              <Label size="s" status="complete">
                {t(`subscription.save`)}
              </Label>
            ) : (
              <Label
                size="s"
                status="in_progress"
                onClick={() => setPaymentPeriod(1)}
              >
                {t(`subscription.saveAlert`)}
              </Label>
            )}
            <PricingTabs>
              {companyInfo.pricing &&
                companyInfo.pricing.map((plan) => {
                  const choosenOption = plan.options[paymentPeriod]
                    .discountedPrice
                    ? plan.options[paymentPeriod].discountedPrice
                    : plan.options[paymentPeriod].price;
                  const planPrice =
                    paymentPeriod === 1
                      ? choosenOption.amountMinor / 1200
                      : choosenOption.amountMinor / 100;

                  return (
                    <PriceBox
                      key={plan.product}
                      currentPlan={
                        companyInfo.subscription
                          ? companyInfo.subscription.product
                          : "FREE"
                      }
                      picked={plan.product === pickedPlan.product}
                      name={plan.product}
                      months={plan.options[paymentPeriod].months}
                      price={planPrice}
                      hasDiscount={
                        plan.options[paymentPeriod].discountedPrice
                          ? true
                          : false
                      }
                      strikedPrice={plan.options[0].price.amountMinor / 100}
                      onClick={() => handlePlanClick(plan)}
                    />
                  );
                })}
            </PricingTabs>
          </ContentBox>
        </div>
        <div>
          <ContentBox bgColor="gradient">
            <h3>{t("subscription.invoiceBox.header")}</h3>
            <p>{t("subscription.invoiceBox.text")}</p>
            <a
              href={`mailto:invoice@ideolo.co?subject=${companyInfo.name} - Invoice request&body=All you have to do is just send this email. We'll reach you within 12h. Please, don't change the subject.`}
            >
              <Button size="lg" color="white">
                {t("subscription.invoiceBox.buttonText")}
              </Button>
            </a>
            <Icon src={InvoiceSvg} />
          </ContentBox>
          {/* <ContentBox>
            <Header size="h3" margin="m">
              {t("subscription.history.header")}
            </Header>
            <Table>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Plan</td>
                  <td>Cost</td>
                  <td>Invoice</td>
                </tr>
              </thead>
              <tbody>
                {[...Array(30)].map(() => (
                  <tr>
                    <td>9 Mar 23</td>
                    <td>Monthly subscription</td>
                    <td>20$</td>
                    <td>
                      <Button size="" color="white">
                        <Icon src={InvoiceSvg} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ContentBox> */}
        </div>
      </GridTwoThird>
      {checkoutModalOpen && (
        <CheckoutModal
          open={checkoutModalOpen}
          pickedPlan={pickedPlan}
          handleModalClose={() => setCheckoutModalOpen(false)}
          handlePayClick={handlePayClick}
        />
      )}
    </MainContainer>
  );
}

export default Subscription;
