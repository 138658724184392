import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ circle }) =>
    circle
      ? css`
          width: 60px;
          height: 60px;
        `
      : css`
          width: 60px;
          opacity: 0.8;
        `}
  border-radius: 12px;
  text-align: center;
  transition: opacity 0.3s ease;
  ${({ circle }) =>
    circle &&
    css`
      background: var(--brand-ideolo);
      margin: 0 0 20px;
    `}

  svg {
    width: ${({ circle }) => (circle ? "70%" : "100%")};
    height: auto;

    path {
      fill: ${({ circle }) => (circle ? "var(--white)" : "white")};
      transition: fill 0.3s ease;
    }
  }

  ${({ circle }) =>
    !circle &&
    css`
      &:hover {
        opacity: 1;
        svg path {
          fill: var(--brand-ideolo);
        }
      }
    `}
`;
