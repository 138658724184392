import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, Outlet } from "react-router-dom";
// import { useTranslation, Trans } from "react-i18next";
import {
  fetchBoardDetails,
  fetchBoardLimits,
  fetchBoardIdeas,
  selectCurrentBoardData,
} from "store/currentBoardSlice";

function BoardDetailsLayout() {
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const currentBoardData = useSelector(selectCurrentBoardData);
  // const { t } = useTranslation();

  useEffect(() => {
    boardId && dispatch(fetchBoardDetails(boardId));
    dispatch(fetchBoardLimits(boardId));
    currentBoardData.ideas.status === "idle" &&
      dispatch(fetchBoardIdeas(boardId));
  }, [dispatch]);

  return <Outlet />;
}

export default BoardDetailsLayout;
