import styled from "styled-components";

export const Wrapper = styled.aside`
  grid-area: options;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  width: 100%;

  > * + * {
    margin-left: 10px;
  }
`;
