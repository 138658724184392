import styled from "styled-components";

export const Wrapper = styled.form`
  position: relative;

  > div:last-child {
    display: flex;
    align-itmes: flex-end;
    justify-content: flex-end;

    * + * {
      margin-left: 10px;
    }
  }
`;
