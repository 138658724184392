const ideoloTheme = {
  breakpoints: ["30em", "48em", "62em", "80em"],
  colors: {
    white: "#ffffff",
    black: "#161616",
    gray: "#828A95",
    consoleBg: "#2a2734",
    brand: "#53d23d",
  },
  fonts: {
    body: "dicsans, sans-serif",
    heading: "dicsans, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    "2xs": "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  fontWeights: {
    light: 300,
    normal: 500,
    bold: 700,
  },
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    submenu: 1150,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  boxShadows: {
    level1: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    level2: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    level3:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
  },
};

export default ideoloTheme;
