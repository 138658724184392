import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBoardDetails,
  selectCurrentBoardData,
} from "store/currentBoardSlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import { useTranslation } from "react-i18next";

import Topbar from "layout/atoms/Topbar";
import GridBoard from "layout/atoms/GridBoard";
import ContentBox from "layout/atoms/ContentBox";

function BoardUsers() {
  const dispatch = useDispatch();
  const currentBoardData = useSelector(selectCurrentBoardData);
  const api = new Api();
  const { t } = useTranslation();
  const [boardDetails, setBoardDetails] = useState({
    id: currentBoardData.id,
  });

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("boardsDetails.users.header")}</h2>
        </div>
      </Topbar>
      <GridBoard>
        <ContentBox>BoardUsers</ContentBox>
      </GridBoard>
    </MainContainer>
  );
}

export default BoardUsers;
