import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
  border-radius: 4px;
  width: 360px;
  padding: 20px;
  background-color: ${({ type }) =>
    type ? `var(--${type.toLowerCase()}-medium)` : "var(--white)"};
  color: var(--white);
  font-size: 12px;
  line-height: 1.33;
  transition: all 0.3s ease;
  will-change: transform, opacity;

  > div:first-child {
    display: flex;
    align-items: flex-start;

    svg path {
      fill: var(--white);
    }

    > div {
      padding-left: 5px;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  > button {
    border-color: ${({ type }) =>
      type ? `var(--${type.toLowerCase()}-medium)` : "var(--white)"};

    svg path {
      fill: var(--white);
    }

    &:hover {
      border-color: ${({ type }) =>
        type ? `var(--${type.toLowerCase()}-medium)` : "var(--white)"};
      ${({ type }) =>
        type && `background-color: var(--${type.toLowerCase()}-light);`}

      svg path {
        fill: ${({ type }) =>
          type ? `var(--${type.toLowerCase()}-medium)` : "var(--white)"};
      }
    }
  }

  ${({ active }) =>
    active
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
        `
      : css`
          transform: translate3d(20px, 0, 0);
          opacity: 0;
        `}
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`;
