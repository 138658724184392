import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";

const api = new Api();

const initialState = {
  user: {},
  isUserLoggedIn: null,
  status: "idle",
  error: null,
};

export const me = createAsyncThunk("auth/me", async () => {
  const response = await api.getUserInfo().then((userInfo) => {
    return userInfo;
  });

  return response;
});

export const logIn = createAsyncThunk("auth/logIn", async (data) => {
  const response = await api
    .login(data.email, data.password)
    .then((loginResponse) => {
      localStorage.setItem("authToken", loginResponse.token);
      return loginResponse;
    });

  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetMe: () => {
      return initialState;
    },
    logoutMe: () => {
      return {
        ...initialState,
        status: "error",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(me.pending, (state) => {
        state.status = "loading";
      })
      .addCase(me.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isUserLoggedIn = true;
        state.status = "succeeded";
      })
      .addCase(me.rejected, (state) => {
        state.isUserLoggedIn = false;
        state.status = "error";
      })
      .addCase(logIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isUserLoggedIn = true;
        state.status = "succeeded";
      })
      .addCase(logIn.rejected, (state) => {
        state.isUserLoggedIn = false;
        state.status = "error";
      });
  },
});

export const { resetMe, logoutMe } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthInfo = (state) => state.auth;
