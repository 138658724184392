import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const Form = ({ onSubmit, children }) => {
  return <Wrapper onSubmit={onSubmit}>{children}</Wrapper>;
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Form;
