import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Label from "layout/atoms/Label";

import { Wrapper, Radio, Top, Price, Footer } from "./styles";

const PriceBox = ({
  currentPlan,
  readOnly,
  picked,
  name,
  months,
  description,
  price,
  hasDiscount,
  strikedPrice,
  onClick,
}) => {
  const { t } = useTranslation();
  const hideOptions = {
    FREE: [],
    STARTUP: [],
    COMPANY: ["STARTUP"],
    ULTRA: ["STARTUP", "COMPANY"],
  }[currentPlan];

  return (
    <Wrapper
      readOnly={readOnly}
      picked={picked}
      onClick={onClick}
      hide={hideOptions.includes(name)}
    >
      <Radio readOnly={readOnly} picked={picked} />
      <Top>
        {name && (
          <h3>
            {t(`subscription.${name}.header`)}{" "}
            {!readOnly && currentPlan === name && (
              <Label size="s" status="complete">
                {t(`subscription.currentPlan.header`)}
              </Label>
            )}
          </h3>
        )}
        {description ? (
          description
        ) : (
          <p>{t(`subscription.${name}.description`)}</p>
        )}
      </Top>
      <Footer>
        <Price>
          {Math.round(price)}$
          {!readOnly && <span>/{t(`subscription.months.1`)}</span>}
        </Price>
        {!readOnly && (months === 12 || hasDiscount) && (
          <Price discount>
            {strikedPrice}$<span>/{t(`subscription.months.1`)}</span>
          </Price>
        )}
      </Footer>
    </Wrapper>
  );
};

PriceBox.propTypes = {
  currentPlan: PropTypes.string,
  readOnly: PropTypes.bool,
  picked: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  price: PropTypes.number,
  hasDiscount: PropTypes.bool,
  strikedPrice: PropTypes.number,
  months: PropTypes.number,
  onClick: PropTypes.func,
};

PriceBox.defaultProps = {
  currentPlan: "FREE",
  readOnly: false,
  picked: false,
  name: "",
  description: null,
  price: 0,
  hasDiscount: false,
  strikedPrice: 0,
  months: 1,
  onClick: () => {},
};

export default PriceBox;
