import styled from "styled-components";

export const Wrapper = styled.section`
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  grid-template-areas:
    "vote options"
    "text text";
  width: 100%;
  margin: 0;
  padding: 20px;
  background: var(--white);
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.boxShadows.level1};

  > div:nth-child(1) {
    grid-area: vote;
    width: 100px;
  }

  & > *:nth-child(2) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 0;
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 11fr 2fr;
    grid-template-areas: "vote text options";
  }
`;

export const Main = styled.div`
  grid-area: text;
  height: 100%;
  padding: 0;

  > h4 {
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 1.33;
    ${({ inModal }) => !inModal && "cursor: pointer;"}
  }

  > p {
    margin: 0 0 12px;
    font-size: 14px;
  }
`;

export const ModalTop = styled.section`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  grid-template-areas:
    "vote options"
    "text text";

  > div:nth-child(1) {
    grid-area: vote;
    width: 100px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 0 0 30px;
    display: grid;
    grid-template-areas:
      "text options"
      "vote options";
    grid-template-columns: 1fr 11fr 2fr;
    grid-template-rows: 1fr;
    grid-template-areas: "vote text options";
  }
`;

export const ModalButtons = styled.section`
  position: absolute;
  top: 26px;
  right: 80px;

  > button {
    margin: 10px 0 0;
  }
`;
