import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Modal from "layout/atoms/Modal";

const AddIdeaModal = ({ open, handleModalClose, boardId, onNewIdeaAdded }) => {
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.forms.addIdea",
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ defaultValues: { title: "", description: "" } });

  const createIdea = (data) => {
    handleModalClose && handleModalClose();
    api
      .createNewIdea(data, boardId)
      .then((response) => {
        onNewIdeaAdded && onNewIdeaAdded();
      })
      .catch((error) => {
        console.log("Error toast");
      });
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
      onNewIdeaAdded && onNewIdeaAdded();
    }
  }, [formState, isSubmitSuccessful, reset]);

  const handleCancelButton = () => {
    reset();
    handleModalClose && handleModalClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <ContentBox size="m" header={<h3>{t("header")}</h3>}>
        <Form onSubmit={handleSubmit(createIdea)}>
          <InputText
            type="text"
            name="title"
            label={t("fields.title.label")}
            placeholder={t("fields.title.placeholder")}
            register={register}
            errors={errors.title}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 100,
            }}
          />
          <InputText
            type="textarea"
            name="description"
            label={t("fields.description.label")}
            placeholder={t("fields.description.placeholder")}
            register={register}
            errors={errors.description}
            validation={{
              minLength: 6,
              maxLength: 500,
            }}
          />
          <div>
            <Button
              type="button"
              size="lg"
              color="white"
              onClick={handleCancelButton}
            >
              {t("buttons.cancel")}
            </Button>
            <Button type="submit" size="lg" color="primary">
              {t("buttons.save")}
            </Button>
          </div>
        </Form>
      </ContentBox>
    </Modal>
  );
};

AddIdeaModal.propTypes = {
  boardId: PropTypes.string,
};

export default AddIdeaModal;
