import React from "react";

import Portal from "layout/atoms/Portal";
import Toast from "layout/atoms/Toast";

import { Wrapper } from "./styles";

const ToastList = ({ list }) => (
  <Portal className="toast-portal">
    <Wrapper>
      {list.map((toast) => (
        <Toast key={toast.id} data={toast} />
      ))}
    </Wrapper>
  </Portal>
);

export default ToastList;
