import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectAuthInfo } from "store/authSlice";

import MainContainer from "layout/atoms/MainContainer";
import Loader from "layout/atoms/Loader";
import EmptyState from "layout/molecules/EmptyState";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <MainContainer alignCenter>
      <EmptyState
        type="notFound"
        header={t("emptyStates.notFound.header")}
        subheader={t("emptyStates.notFound.subheader")}
        button={t("emptyStates.notFound.button")}
      />
    </MainContainer>
  );
};

export default NotFound;
