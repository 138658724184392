import styled from "styled-components";

export const Wrapper = styled.section`
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "vote"
    "text";
  width: 100%;
  padding: 20px;
  background: var(--white);
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.boxShadows.level1};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 12fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "vote text";
  }
`;

export const DateAndOptions = styled.section`
  grid-area: vote;
  display: flex;
  flex-direction: column;
  max-width: 200px;

  h3 {
    margin: 0 0 10px;
    padding: 5px;
    text-align: center;
    color: var(--brand);
    white-space: nowrap;
    transition: all 0.3s ease;

    span {
      display: block;
      margin: 5px 0 0;
      // font-size: 0.9em;
    }
  }
`;

export const Buttons = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
  display: flex;

  > * + * {
    margin: 0 0 0 10px;
  }
`;

export const Main = styled.div`
  grid-area: text;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 0 30px 20px;
  }

  h4 {
    margin: 0 0 12px;
  }

  > p {
    margin: 0 0 12.5px;
  }

  ul {
    li {
      margin: 0 0 8px;

      > span {
        position: relative;
        padding: 5px 0;
      }

      > span > button {
        position: absolute;
        top: 50%;
        right: -40px;
        transform: translate3d(-20px, -50%, 0);
        opacity: 0;
        transition: all 0.3s ease;
      }

      &:hover > span > button {
        transform: translate3d(0, -50%, 0);
        opacity: 1;
      }
    }
  }
`;
