import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { clearCurrentBoard } from "store/currentBoardSlice";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import GridTwoThird from "layout/atoms/GridTwoThird";
import Header from "layout/atoms/Header";
import InputText from "layout/atoms/InputText";
import MainContainer from "layout/atoms/MainContainer";
import Topbar from "layout/atoms/Topbar";

function ProfileSettings() {
  const dispatch = useDispatch();
  let api = new Api();
  const { t } = useTranslation();
  const initFormData = {
    displayName: "",
    lang: "pl",
    savign: false,
  };
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      password: "password",
    },
  });

  const [formData, setFormData] = useState(initFormData);
  const [savingForm, setSavingForm] = useState(false);

  const fetchUserData = async () => {
    const fetchedUserData = await api.getUserInfo().then((userData) => {
      setFormData({ ...formData, userData });
    });

    return fetchedUserData;
  };

  const saveUserData = () => {
    setSavingForm(true);
    setTimeout(() => setSavingForm);
  };

  useEffect(() => {
    dispatch(clearCurrentBoard());
    fetchUserData();
  }, [dispatch]);

  return (
    <MainContainer>
      <Topbar>
        <h2>{t("profileSettings.title")}</h2>
      </Topbar>
      <GridTwoThird>
        <div>
          <Form onSubmit={() => {}}>
            {/* <ContentBox>
              <Header size="h3" margin="m">
                General
              </Header>
              <InputText
                type="text"
                name="name"
                label={t("boardsDetails.forms.settings.fields.name.label")}
                register={register}
                errors={errors.name}
                validation={{
                  required: true,
                  minLength: 6,
                  maxLength: 100,
                }}
              />
              <footer>
                <Button size="lg" color="white" to="/">
                  {t("buttons.cancel")}
                </Button>
                <Button type="submit" size="lg" color="primary">
                  {t("buttons.save")}
                </Button>
              </footer>
            </ContentBox> */}
            <ContentBox>
              <Header size="h3" margin="m">
                Password
              </Header>
              <InputText
                type="password"
                name="password"
                label={t("loginForm.passwordLabel")}
                register={register}
                value="ppppp"
                disabled
              />
              <footer>
                <Button size="lg" color="primary">
                  Change password
                </Button>
              </footer>
            </ContentBox>
          </Form>
        </div>
      </GridTwoThird>
    </MainContainer>
  );
}

export default ProfileSettings;
