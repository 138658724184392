import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { supportedLangs } from "i18n";
import { selectUserInfo } from "store/userSlice";
import { selectCompanyInfo } from "store/companySlice";
import { selectAllToasts } from "store/toastsSlice";

import { selectCurrentBoardData } from "store/currentBoardSlice";
import PropTypes from "prop-types";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

import ideoloTheme from "layout/theme/";
import "layout/theme/sanitize.css";

import MenuToolbar from "layout/molecules/MenuToolbar";
import Submenu from "layout/molecules/Submenu";
import ToastList from "layout/atoms/ToastList";

import { mainNavigation } from "constants/mainNavigation";
import { boardNavigation } from "constants/boardNavigation";

const GlobalStyle = createGlobalStyle`
  :root {
    --text-color: #ffffff;
    --white: #ffffff;
    --white-rgba: rgba(250, 250, 250, 1);
    --white-rgba2: rgba(250, 250, 250, 0);
    --white2: #ededed;
    --black: #161616;
    --black2: #222222;
    --gray: #828A95;
    --consoleBg: #2a2734;
    --brand-ideolo: #53d23d;
    --brand: #${({ currentBoardData }) =>
      currentBoardData.color ? currentBoardData.color : "53d23d"};
    --status-all-light: #fff;
    --status-all-medium: #000;
    --status-not_public-light: #fdf3f3;
    --status-not_public-medium: #c43637;
    --status-open-light: #f0f7ff;
    --status-open-medium: #0267e5;
    --status-review-light: #f7f7f8;
    --status-review-medium: #4f4e4f;
    --status-planned-light: #f7f4fe;
    --status-planned-medium: #8f39ed;
    --status-in_progress-light: #fdf8e5;
    --status-in_progress-medium: #ebbd23;
    --status-complete-light: #e6f6e4;
    --status-complete-medium: #11800b;
    --success-light: #e6f6e4;
    --success-medium: #11800b;
    --success-dark: #0c5c07;
    --error-light: #fdf3f3;
    --error-medium: #c43637;
    --error-dark: #962727;
    --warning-light: #fdf8e5;
    --warning-medium: #ebbd23;
    --warning-dark: #b59018;
    --info-light: #f0f7ff;
    --info-medium: #0267e5;
    --info-dark: #0051b5;
    --body-bg: #fafafa;
    --menu-toolbar-bg: #222222;
    --submenu-bg: #ffffff;
    --content-box-bg: #ffffff;
  }

  html {
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 12px;
      background-color: rgba(0,0,0,0.05);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      -webkit-box-shadow: inset 0 0 0
      rgba(0,0,0,0.4);
      background-color: rgba(0,0,0,0.15);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 white;
      border-radius: 0;
      background-color: rgba(0,0,0,0.05);
    }
  }

  body {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 400;
    color: var(--black);
    background: var(--body-bg);
  }

  body.dark-theme {
    --white: #222222;
    --white-rgba: rgba(15, 15, 15, 1);
    --white-rgba2: rgba(15, 15, 15, 0);
    --black: #ffffff;
    --black2: #ffffff;
    --body-bg: #0f0f0f;
    --menu-toolbar-bg: #0f0f0f;
    --submenu-bg: #222222;
    --content-box-bg: #222222;
  }

  .portal-container {
    position: relative;
    z-index: 9000;
  }

  [data-floating-ui-portal] {
    position: relative;
    z-index: 9900;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    html {
      overflow-y: scroll;
    }
  }
`;

const StyledWrapper = styled.div`
  padding: 0;
  transition: padding 0.6s ease;
  will-change: padding;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 0 0 0
      ${({ showMenuToolbar, showSubmenu }) =>
        showMenuToolbar ? (showSubmenu ? "340px" : "80px") : "0"};
  }
`;

const Layout = ({ showMenuToolbar = false, submenuData, children }) => {
  let navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentBoardData = useSelector(selectCurrentBoardData);
  const toasts = useSelector(selectAllToasts);
  const userInfo = useSelector(selectUserInfo);
  const companyInfo = useSelector(selectCompanyInfo);
  const isLoggedIn = companyInfo.id;
  const newBrandColors = currentBoardData.color
    ? `#${currentBoardData.color}`
    : ideoloTheme.colors.brand;

  const getBrowserLocales = () => {
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();

      return trimmedLocale.split(/-|_/)[0];
    });
  };

  useEffect(() => {
    let langMatched =
      userInfo.lang !== ""
        ? userInfo.lang
        : getBrowserLocales().find((lang) => supportedLangs.includes(lang));

    langMatched ? i18n.changeLanguage(langMatched) : i18n.changeLanguage("en");

    companyInfo.error && navigate(`/login`);
  }, []);

  return (
    <ThemeProvider
      newBrandColors={newBrandColors}
      theme={{
        ...ideoloTheme,
        colors: {
          ...ideoloTheme.colors,
          brand: newBrandColors,
        },
      }}
    >
      <Helmet>
        <title>
          {currentBoardData.name
            ? `${currentBoardData.name} | Ideolo Board`
            : `Ideolo App`}
        </title>
      </Helmet>
      <GlobalStyle currentBoardData={currentBoardData} />
      <StyledWrapper
        showMenuToolbar={
          showMenuToolbar && window.location.pathname.startsWith("/admin/")
        }
        showSubmenu={currentBoardData.id}
      >
        <MenuToolbar
          isActive={
            showMenuToolbar && window.location.pathname.startsWith("/admin/")
          }
          navItems={mainNavigation}
        />
        <Submenu
          isActive={showMenuToolbar && currentBoardData.id}
          boardName={currentBoardData.name}
          navItems={boardNavigation(currentBoardData.id)}
        />
        <Outlet />
        {toasts && <ToastList list={toasts} />}
      </StyledWrapper>
    </ThemeProvider>
  );
};

export default Layout;
