import React from "react";
import PropTypes from "prop-types";

import Icon from "layout/atoms/Icon";

import { Wrapper, MenuIcon } from "./styles";
import menuSvg from "assets/icons/menu.svg";

const Hamburger = ({ isOpened, onClick }) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Wrapper isOpened={isOpened} onMouseDown={handleClick}>
      <MenuIcon src={menuSvg} />
    </Wrapper>
  );
};

Hamburger.propTypes = {
  isOpened: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Hamburger;
