import React, { useState, useEffect, useRef } from "react";
import "wicg-inert";

import Button from "layout/atoms/Button";
import Icon from "layout/atoms/Icon";
import MainContainer from "layout/atoms/MainContainer";
import Portal from "layout/atoms/Portal";

import { Backdrop, Content, CloseButton } from "./styles";
import CloseSvg from "assets/icons/close.svg";

const Modal = (props) => {
  const [active, setActive] = useState(false);
  const { open, onClose, locked } = props;
  const backdrop = useRef(null);

  useEffect(() => {
    const { current } = backdrop;
    const transitionEnd = () => setActive(open);
    const keyHandler = (e) =>
      !locked && [27].indexOf(e.which) >= 0 && onClose();
    const clickHandler = (e) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector("#root").setAttribute("inert", "true");
        document.body.style.overflow = "hidden";
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
      document.body.style.overflow = "unset";
      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open && "active"}>
            <Content className="modal-content">
              {props.children}
              <CloseButton>
                <Button size="lg-square" color="white" onClick={onClose}>
                  <Icon src={CloseSvg} />
                </Button>
              </CloseButton>
            </Content>
          </Backdrop>
        </Portal>
      )}
    </>
  );
};

export default Modal;
