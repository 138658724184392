import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./filters.module.scss";

const Filters = ({ filters, sorts, billed }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.wrap}`}>
      {filters && (
        <span>
          {t("show")} &nbsp;{filters}
        </span>
      )}
      {sorts && (
        <span>
          {t("sortBy")} &nbsp;{sorts}
        </span>
      )}
      {billed && (
        <span>
          {t("billed")} &nbsp;{billed}
        </span>
      )}
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sorts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pay: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Filters;
