import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Modal from "layout/atoms/Modal";
import PriceBox from "layout/atoms/PriceBox";

import PayPalIcon from "assets/paypal.png";

const CheckoutModal = ({
  open,
  pickedPlan,
  handleModalClose,
  handlePayClick,
}) => {
  const { t } = useTranslation("translation");

  const handleCancelButton = () => {
    handleModalClose && handleModalClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <ContentBox
        size="m"
        header={<h3>{t("subscription.checkout.header")}</h3>}
        footer={
          <>
            <div />
            <div>
              <Button size="lg" color="white" onClick={handleCancelButton}>
                {t("buttons.cancel")}
              </Button>
              <Button
                type="submit"
                size="lg"
                color="primary"
                onClick={handlePayClick}
              >
                {t("subscription.checkout.payButton")}
              </Button>
            </div>
          </>
        }
      >
        <h4>{t("subscription.checkout.pickedPlan")}</h4>
        <br />
        <PriceBox
          key={pickedPlan.product}
          picked={true}
          readOnly={true}
          name={pickedPlan.product}
          description={
            <p>
              {t(`subscription.checkout.for`)} {pickedPlan.months}{" "}
              {t(
                `subscription.checkout.months.${
                  pickedPlan.months > 1 ? "plural" : "singular"
                }`
              )}
            </p>
          }
          months={pickedPlan.months}
          price={
            pickedPlan.discountedPrice
              ? pickedPlan.discountedPrice.amountMinor / 100
              : pickedPlan.price.amountMinor / 100
          }
          discountPrice={
            pickedPlan.discountedPrice
              ? pickedPlan.price.amountMinor / 100
              : null
          }
        />
        <br />
        <br />
        <h4>{t("subscription.checkout.paymentMethod")}</h4>
        <br />
        <img src={PayPalIcon} width="200px" />
      </ContentBox>
    </Modal>
  );
};

CheckoutModal.propTypes = {
  boardId: PropTypes.string,
};

export default CheckoutModal;
