import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: max-content;
  cursor: pointer;
`;

export const DropdownBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 4px;
  width: max-content;
  padding: 10px 15px;
  background: var(--white);
  box-shadow: ${({ theme }) => theme.boxShadows.level2};
  opacity: ${({ animated }) => (animated ? "1" : "0")};
  transform: translate3d(0, ${({ animated }) => (animated ? "0" : "-10px")}, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  will-change: transform, opacity;

  > * {
    cursor: pointer;
  }

  > * + * {
    margin-top: 10px !important;
  }
`;
