import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  grid-area: vote;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  cursor: pointer;

  > div {
    margin: 0 0 10px;
    border: 1px solid var(--brand);
    border-radius: 4px;
    padding: 8px 16px;
    text-align: center;
    background-color ${({ isVotedByMe }) =>
      isVotedByMe ? "var(--brand)" : "var(--white)"};
      transition: background-color 0.3s ease;

    strong {
      display: block;
      font-size: 24px;
      font-weight: 600;
      color: ${({ isVotedByMe }) =>
        isVotedByMe ? "var(--white)" : "var(--brand)"};
    }
  }
`;

export const HeartIconWrapper = styled.div`
  position: relative;

  svg:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center center;
    transition: all 0.3s ease;
  }

  ${({ isVotedByMe }) =>
    isVotedByMe &&
    css`
      svg path {
        fill: var(--brand);
      }

      svg:nth-child(2) {
        width: 24px;
        height: 24px;
        animation: liked 0.4s ease;
      }
    `}

  @keyframes liked {
    0% {
      width: 0;
      height: 0;
    }
    50% {
      width: 34px;
      height: 34px;
    }
    100% {
      width: 24px;
      height: 24px;
    }
  }
`;
