import React from "react";
import PropTypes from "prop-types";

import Icon from "layout/atoms/Icon";

import { Wrapper, Square, Desc } from "./styles";
import CheckmarkSvg from "assets/icons/checkmark/checkmark.svg";

const InputCheckbox = ({ checked, disabled, onChange, children }) => {
  const handleClick = (e) => {
    onChange && onChange(e);
  };

  return (
    <Wrapper disabled={disabled}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleClick}
      />
      <Square checked={checked}>
        <div>
          <Icon src={CheckmarkSvg} />
        </div>
      </Square>
      <Desc>{children}</Desc>
    </Wrapper>
  );
};

InputCheckbox.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

InputCheckbox.defaultProps = {
  children: null,
};

export default InputCheckbox;
