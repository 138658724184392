import styled from "styled-components";

export const Wrapper = styled.aside`
  grid-area: options;
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: 10px;
  }
`;
