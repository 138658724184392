import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "layout/atoms/Icon";

import { Wrapper } from "./styles";
import ArrowSvg from "assets/icons/arrow2/right.svg";
import Label from "layout/atoms/Label";

const BoardCard = ({ boardData }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <Wrapper
      key={boardData.id}
      active={boardData.active}
      header={
        <div>
          <h3>{boardData.name}</h3>
          {!boardData.active && (
            <Label size="s" status="REVIEW">
              {t("statuses.INACTIVE_BOARD")}
            </Label>
          )}
        </div>
      }
      footer={
        <div>
          <Icon src={ArrowSvg} />
        </div>
      }
      onClick={() => navigate(`/admin/boards/${boardData.id}/list`)}
      color={boardData.theme.primary}
    />
  );
};

BoardCard.propTypes = {
  boardData: PropTypes.object,
};

export default BoardCard;
