import styled from "styled-components";

export const Wrapper = styled.nav`
  z-index: ${({ theme }) => theme.zIndices.banner};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0;
  width: 100vw;
  height: 70px;
  padding: 0 10px 0 20px;
  transform: translate3d(0, ${({ active }) => (active ? "0" : "-100%")}, 0);
  transition: transform 0.6s ease;
  will-change: transform;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--menu-toolbar-bg);
    box-shadow: ${({ theme }) => theme.boxShadows.level3};
    content: "";
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: column;
    justify-content: flex-start;
    width: 84px;
    height: 100vh;
    padding: 0 13px;
    background: var(--menu-toolbar-bg);
    box-shadow: ${({ theme }) => theme.boxShadows.level3};
    transform: translate3d(${({ active }) => (active ? "0" : "-100%")}, 0, 0);

    &::before {
      display: none;
    }
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 40px;
    height: 80px;
  }
`;

export const NavList = styled.nav`
  z-index: -1;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--menu-toolbar-bg);
  transform: translate3d(0, ${({ isOpened }) => (isOpened ? "0" : "-100%")}, 0);
  transition: transform 0.6s ease;

  > ul {
    list-style: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: static;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5px 0;
    transform: none;
  }
`;

export const NavItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;

  &:last-child {
    align-self: last baseline;
  }

  a {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;

    svg {
      opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
      transition: opacity 0.3s ease;

      path {
        fill: #fff;
      }
    }

    p {
      display: block;
      padding: 5px 10px;
      font-size: 14px;
      text-align: center;
    }

    &.active {
      svg {
        opacity: 1;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
    height: auto;
    padding: 0;

    a {
      padding: 35px 0;

      p {
        position: absolute;
        top: 50%;
        left: 60px;
        display: block;
        max-width: 1000px;
        margin: 0;
        font-size: ${({ theme }) => theme.fontSizes.xs};
        text-align: left;
        white-space: nowrap;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 4px;
        opacity: 0;
        pointer-events: none;
        transform: translate3d(0, -50%, 0);
        transition: all 0.6s ease;
      }

      &:hover {
        svg {
          opacity: 1;
        }

        p {
          opacity: 1;
          transform: translate3d(10px, -50%, 0);
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: block;
  }
`;
