import React, { useEffect } from "react";
import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { me, selectAuthInfo } from "store/authSlice";

import Layout from "layout";
import Index from "containers/Index";

import AdminLayout from "containers/admin/AdminLayout";
import Dashboard from "containers/admin/Dashboard";
import BoardsList from "containers/admin/boards/BoardsList";
import BoardDetailsLayout from "containers/admin/boards/BoardDetails/BoardDetailsLayout";
import BoardDetails from "containers/admin/boards/BoardDetails/BoardDetails";
import BoardRoadmap from "containers/admin/boards/BoardDetails/Roadmap";
import BoardReleases from "containers/admin/boards/BoardDetails/Releases";
import BoardUsers from "containers/admin/boards/BoardDetails/Users";
import BoardWidget from "containers/admin/boards/BoardDetails/Widget";
import BoardSettings from "containers/admin/boards/BoardDetails/Settings";
import UsersList from "containers/admin/UsersList";
import Subscription from "containers/admin/Subscription";
import ProfileSettings from "containers/admin/ProfileSettings";

import LoginForm from "containers/LoginForm";
import LogoutForm from "containers/LogoutForm";
import SignupForm from "containers/SignupForm";
import ResetPasswordForm from "containers/ResetPasswordForm";
import ResetPasswordNew from "containers/ResetPasswordNew";
import ResetPasswordSent from "containers/ResetPasswordSent";
import ResetPasswordToken from "containers/ResetPasswordToken";

import PublicView from "containers/PublicView";
import NotFound from "containers/NotFound";

function App() {
  const dispatch = useDispatch();
  const authInfo = useSelector(selectAuthInfo);

  useEffect(() => {
    dispatch(me());
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout showMenuToolbar={authInfo.isUserLoggedIn} />}>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/logout" element={<LogoutForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/signup/:ref" element={<SignupForm />} />

        <Route path="/password-reset">
          <Route index element={<ResetPasswordForm />} />
          <Route path="sent" element={<ResetPasswordSent />} />
          <Route path="new/:email/:token" element={<ResetPasswordNew />} />
          <Route path=":email/:token" element={<ResetPasswordToken />} />
        </Route>

        <Route path="/admin" element={<AdminLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/boards">
            <Route index element={<BoardsList />} />
            <Route
              path="/admin/boards/:boardId"
              shouldRevalidate={false}
              element={<BoardDetailsLayout />}
            >
              <Route index element={<BoardDetails />} />
              <Route
                path="/admin/boards/:boardId/list"
                element={<BoardDetails />}
              />
              <Route
                path="/admin/boards/:boardId/roadmap"
                element={<BoardRoadmap />}
              />
              <Route
                path="/admin/boards/:boardId/releases"
                element={<BoardReleases />}
              />
              <Route
                path="/admin/boards/:boardId/users"
                element={<BoardUsers />}
              />
              <Route
                path="/admin/boards/:boardId/widget"
                element={<BoardWidget />}
              />
              <Route
                path="/admin/boards/:boardId/settings"
                element={<BoardSettings />}
              />
            </Route>
          </Route>
          <Route path="/admin/users" element={<UsersList />} />
          <Route path="/admin/subscription" element={<Subscription />} />
          <Route path="/admin/settings" element={<ProfileSettings />} />
        </Route>

        <Route
          path="/public/:companySlug/:boardSlug"
          element={<PublicView />}
        />
        <Route path="/:companySlug/:boardSlug" element={<PublicView />} />
        <Route path="/*" element={<NotFound />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
