import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import Api from "api/Api";
import {useSelector} from "react-redux";
import {selectReleaseById} from "store/releasesSlice";

import Button from "layout/atoms/Button";
import Dropdown from "layout/atoms/Dropdown";
import Icon from "layout/atoms/Icon";
import AddReleaseModal from "layout/organisms/AddReleaseModal";
import AddIdeasAsReleasePartsModal from "layout/organisms/AddIdeasAsReleasePartsModal";
import AddGenericReleasePartModal from "layout/organisms/AddGenericReleasePartModal";

import {Buttons, DateAndOptions, Main, Wrapper} from "./styles";
import PublishSvg from "assets/icons/eye.svg";
import UnpublishSvg from "assets/icons/eye-off2.svg";
import AddIdeasSvg from "assets/icons/bulb.svg";
import EditSvg from "assets/icons/edit1.svg";
import Edit2Svg from "assets/icons/edit2.svg";
import TrashSvg from "assets/icons/trash.svg";
import Label from "layout/atoms/Label";

const ReleaseCard = ({ releaseId, name, releaseDate }) => {
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.releaseCard",
  });
  const releaseDetails = useSelector((state) =>
    selectReleaseById(state, releaseId)
  );
  const [isPublished, setIsPublished] = useState(releaseDetails.isPublished);
  const [releaseParts, setReleaseParts] = useState(releaseDetails.parts);
  const [updateReleaseModal, setUpdateReleaseModal] = useState(false);
  const [addIdeasAsReleasePartsModal, setAddIdeasAsReleasePartsModal] =
    useState(false);
  const [addGenericReleasePartsModal, setAddGenericReleasePartsModal] =
    useState(false);

  // const fetchRelease = () => {
  //   return api.getIdea(currentReleaseId, boardId).then((newIdeaDetails) => {
  //     setIdeaDetails({
  //       ...releaseDetails,
  //       newIdeaDetails,
  //     });
  //   });
  // };

  // const handleTitleClick = (inModal) => {
  //   fetchRelease();
  //   !inModal && setModalOpened(!modalOpened);
  // };

  const handlePublishing = () => {
    return api
      .changePublishRelease(
        { published: isPublished ? false : true },
        releaseId
      )
      .then((response) => {
        setIsPublished(!isPublished);
      })
      .catch((error) => {
        // toast
      });
  };

  const handlePartsUpdate = async () => {
    return await api
      .getReleaseDetails(releaseId)
      .then((response) => {
        setReleaseParts(response.parts);
      })
      .catch((error) => {
        // toast
      });
  };

  const handlePartRemove = async (partId) => {
    setReleaseParts(releaseParts.filter((item) => item.id !== partId));
    return await api
      .deleteReleasePart(partId, releaseId)
      .then(() => {
        // setReleaseParts(releaseParts.filter((item) => item.id !== partId));
      })
      .catch((error) => {
        // toast
      });
  };

  const MainContent = ({ inModal = false }) => {
    return (
      <Main inModal={inModal}>
        {releaseDetails && <h4>{releaseDetails.name}</h4>}
        <ul>
          {releaseParts &&
            releaseParts.map((part) => (
              <li key={part.id}>
                <span>
                  {part.title}
                  <Button
                    size="sm-square"
                    color="white"
                    onClick={() => handlePartRemove(part.id)}
                  >
                    <Icon src={TrashSvg} />
                  </Button>
                </span>
              </li>
            ))}
        </ul>
        <br />
        <Button
          size="sm"
          color="white"
          onClick={() => setAddGenericReleasePartsModal(true)}
        >
          <Icon src={Edit2Svg} />
          <p>{t("addGenericPart")}</p>
        </Button>
      </Main>
    );
  };

  return (
    <Wrapper>
      <DateAndOptions>
        <h3>{releaseDetails.releaseDate}</h3>
        {!isPublished && <Label status="IN_PROGRESS">{t("unpublished")}</Label>}
      </DateAndOptions>
      <MainContent />
      <div />
      <Buttons>
        <Button
          size="lg"
          color="white"
          onClick={() => setAddIdeasAsReleasePartsModal(true)}
        >
          <Icon src={AddIdeasSvg} />
          <p>{t("assignIdeas")}</p>
        </Button>
        <Dropdown closeOnClick>
          <Button size="lg" color="white" onClick={handlePublishing}>
            {isPublished ? (
              <Icon src={UnpublishSvg} />
            ) : (
              <Icon src={PublishSvg} />
            )}
            {isPublished ? <p>{t("unpublish")}</p> : <p>{t("publish")}</p>}
          </Button>
          <Button
            size="lg"
            color="white"
            onClick={() => setAddIdeasAsReleasePartsModal(true)}
          >
            <Icon src={AddIdeasSvg} />
            <p>{t("assignIdeas")}</p>
          </Button>
          <Button
            size="lg"
            color="white"
            onClick={() => setAddGenericReleasePartsModal(true)}
          >
            <Icon src={Edit2Svg} />
            <p>{t("addGenericPart")}</p>
          </Button>
          <Button
            size="lg"
            color="white"
            onClick={() => setUpdateReleaseModal(true)}
          >
            <Icon src={EditSvg} />
            <p>{t("edit")}</p>
          </Button>
          <Button size="lg" color="white" onClick={() => {}} disabled>
            <Icon src={TrashSvg} />
            <p>{t("remove")}</p>
          </Button>
        </Dropdown>
      </Buttons>
      {updateReleaseModal && (
        <AddReleaseModal
          releaseId={releaseId}
          name={name}
          releaseDate={releaseDate}
          open={updateReleaseModal}
          handleModalClose={() => setUpdateReleaseModal(false)}
          onNewReleaseAdded={() => {}}
        />
      )}
      {addIdeasAsReleasePartsModal && (
        <AddIdeasAsReleasePartsModal
          releaseId={releaseId}
          open={addIdeasAsReleasePartsModal}
          handleModalClose={() => setAddIdeasAsReleasePartsModal(false)}
          onNewReleasePartAdded={handlePartsUpdate}
        />
      )}
      {addGenericReleasePartsModal && (
        <AddGenericReleasePartModal
          releaseId={releaseId}
          open={addGenericReleasePartsModal}
          handleModalClose={() => setAddGenericReleasePartsModal(false)}
          onNewReleasePartAdded={handlePartsUpdate}
        />
      )}
    </Wrapper>
  );
};

ReleaseCard.propTypes = {
  boardId: PropTypes.string,
  releaseId: PropTypes.string,
  title: PropTypes.string,
  releaseDate: PropTypes.string,
  canEdit: PropTypes.bool,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ReleaseCard;
