import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCurrentBoard } from "store/currentBoardSlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";

import GridBoard from "layout/atoms/GridBoard";
import Loader from "layout/atoms/Loader";
import Topbar from "layout/atoms/Topbar";

import UserCard from "layout/molecules/UserCard";
import EmptyState from "layout/molecules/EmptyState";

function UsersList() {
  const dispatch = useDispatch();
  let api = new Api();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    setIsLoading(true);
    const companyId = await api.getCompanyInfo().then((companyInfo) => {
      return companyInfo.companyId;
    });

    const fetchedUsers = await api.getUsers(companyId).then((newUsers) => {
      setIsLoading(false);
      setUsers(newUsers);
    });
  };

  useEffect(() => {
    dispatch(clearCurrentBoard());
    fetchUsers();
  }, [dispatch]);

  return (
    <MainContainer>
      <Topbar>
        <div>
          <h2>{t("usersList.title")}</h2>
        </div>
      </Topbar>
      {isLoading ? (
        <Loader />
      ) : (
        <GridBoard>
          {users.length !== 0 ? (
            users
              .sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })
              .map((user) => <UserCard userData={user} />)
          ) : (
            <EmptyState
              type="noUsers"
              header={t("emptyStates.users.header")}
              subheader={t("emptyStates.users.subheader")}
            />
          )}
        </GridBoard>
      )}
    </MainContainer>
  );
}

export default UsersList;
