import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 360px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
`;
