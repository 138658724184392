import React, { useState, useEffect } from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentBoard } from "store/currentBoardSlice";
import { logIn, selectAuthInfo } from "store/authSlice";
import { addToast } from "store/toastsSlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Logo from "layout/atoms/Logo";
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = () => {
  let navigate = useNavigate();
  let params = useParams();
  const dispatch = useDispatch();
  const authInfo = useSelector(selectAuthInfo);
  let api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "resetPassword",
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  const handleLogin = (data) => {
    api
      .passwordRecoverySetNew(params.email, data.password, params.token)
      .then((response) => {
        navigate(`/login`);
        dispatch(
          addToast({
            title: "toasts.resetPasswordSuccess.title",
            description: "toasts.resetPasswordSuccess.description",
            type: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          addToast({
            title: "toasts.logIn.title",
            description: "toasts.logIn.description",
            type: "error",
          })
        );
      });
  };

  useEffect(() => {
    dispatch(clearCurrentBoard());
  }, [dispatch]);

  return authInfo.isUserLoggedIn ? (
    <Navigate to={`/`} />
  ) : (
    <MainContainer alignCenter>
      <Logo circle />
      <Form onSubmit={handleSubmit(handleLogin)}>
        <ContentBox
          size="s"
          centeredHeader
          header={<h2>{t("title")}</h2>}
          footer={
            <>
              <div />
              <Button type="submit" size="lg" color="primary">
                {t("button")}
              </Button>
            </>
          }
        >
          <InputText
            type="password"
            name="password"
            label={t("passwordLabel")}
            register={register}
            errors={errors.email}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 50,
            }}
          />
          <InputText
            type="password"
            name="confirmPassword"
            label={t("confirmPasswordLabel")}
            register={register}
            errors={errors.email}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 50,
            }}
          />
        </ContentBox>
      </Form>
    </MainContainer>
  );
};

export default ResetPasswordForm;
