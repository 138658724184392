import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const Link = ({ center, underline, children }) => {
  return (
    <Wrapper center={center} underline={underline}>
      {children}
    </Wrapper>
  );
};

Link.defaultProps = {
  center: false,
  underline: false,
  children: null,
};

Link.propTypes = {
  center: PropTypes.bool,
  underline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Link;
