import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewRelease,
  updateRelease,
  selectReleaseById,
} from "store/releasesSlice";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import DatePicker from "layout/atoms/DatePicker";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Modal from "layout/atoms/Modal";

const AddReleaseModal = ({
  open,
  companyId,
  boardId,
  releaseId,
  handleModalClose,
}) => {
  const api = new Api();
  const dispatch = useDispatch();
  let releaseDetails = useSelector((state) =>
    selectReleaseById(state, releaseId)
  );
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.forms.addRelease",
  });
  let defaultValues = releaseId
    ? {
        id: releaseId,
        name: releaseDetails.name,
        releaseDate: releaseDetails.releaseDate,
      }
    : {
        companyId: companyId,
        name: "",
        releaseDate: "",
        boardIds: [boardId],
      };
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });

  const createIdea = async (data) => {
    handleModalClose && handleModalClose();
    releaseId ? dispatch(updateRelease(data)) : dispatch(addNewRelease(data));
  };

  useEffect(() => {
    defaultValues = releaseId
      ? {
          id: releaseId,
          name: releaseDetails.name,
          releaseDate: releaseDetails.releaseDate,
        }
      : {
          companyId: companyId,
          name: "",
          releaseDate: "",
          boardIds: [boardId],
        };
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      defaultValues = releaseId
        ? {
            id: releaseId,
            name: releaseDetails.name,
            releaseDate: releaseDetails.releaseDate,
          }
        : {
            companyId: companyId,
            name: "",
            releaseDate: "",
            boardIds: [boardId],
          };
      reset(defaultValues);
    }
  }, [formState, isSubmitSuccessful, reset, releaseDetails]);

  const handleCancelButton = () => {
    reset();
    handleModalClose && handleModalClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <ContentBox
        header={<h3>{t(releaseId ? "editMode.header" : "header")}</h3>}
      >
        <Form onSubmit={handleSubmit(createIdea)}>
          <InputText
            type="text"
            name="name"
            label={t("fields.name.label")}
            placeholder={t("fields.name.placeholder")}
            register={register}
            errors={errors.name}
            validation={{
              required: true,
              minLength: 1,
              maxLength: 16,
            }}
          />
          <DatePicker
            type="text"
            name="releaseDate"
            label={t("fields.releaseDate.label")}
            placeholder={t("fields.releaseDate.placeholder")}
            register={register}
            errors={errors.releaseDate}
            setValue={setValue}
            validation={{
              required: true,
              minLength: 6,
            }}
          />
          <div>
            <Button
              type="button"
              size="lg"
              color="white"
              onClick={handleCancelButton}
            >
              {t("buttons.cancel")}
            </Button>
            <Button type="submit" size="lg" color="primary">
              {t(releaseId ? "editMode.buttons.save" : "buttons.save")}
            </Button>
          </div>
        </Form>
      </ContentBox>
    </Modal>
  );
};

AddReleaseModal.propTypes = {
  companyId: PropTypes.string,
  boardId: PropTypes.string,
};

export default AddReleaseModal;
