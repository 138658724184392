import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  z-index: 5000;
  position: relative;

  > div {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 20px;
  }

  input {
    position: relative;
    display: flex;
    align-items: center;
    outline: none;
    width: 100%;
    max-width: 100px;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 36px;
    border: 1px solid;
    border-radius: 4px;
    background-color: var(--white);
    transition: all 0.2s;
  }
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 600;
`;

export const Indicator = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};

  input {
    z-index: 2;
    position: absolute;
    left: 400%;
    width: 200px;
  }
`;

export const Picker = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin: 5px 0 0;
  transform: translate3d(0, 0, 0);
  ${({ active }) =>
    active
      ? css`
          display: block;
        `
      : `display: none;`}
`;
