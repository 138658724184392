import React from "react";
import PropTypes from "prop-types";

import Icon from "layout/atoms/Icon";

import { Wrapper, Label, Error } from "./styles";
import ArrowSvg from "assets/icons/arrow1/down.svg";

const Select = ({
  type = "text",
  name,
  label,
  placeholder = "",
  defaultValue = "",
  register,
  validation,
  errors,
  options,
}) => {
  const inputProps = {
    name,
    placeholder,
    defaultValue,
  };

  return (
    <Wrapper hasErrors={errors}>
      <Label htmlFor={name}>{label}</Label>
      <div>
        <select
          name={name}
          placeholder={placeholder}
          {...inputProps}
          {...register(name, validation)}
        >
          {options.map((option, index) => (
            <option key={option.value + index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        <Icon src={ArrowSvg} />
      </div>
    </Wrapper>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default Select;
