import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const Options = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

Options.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Options;
