import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";

const api = new Api();

const initialState = {
  releases: [],
  status: "idle",
  error: null,
};

export const fetchReleases = createAsyncThunk(
  "releases/fetchReleases",
  async (boardId) => {
    const response = await api.getBoardReleases(boardId);

    return response;
  }
);

export const addNewRelease = createAsyncThunk(
  "releases/addNewRelease",
  async (data) => {
    const response = await api.createNewRelease(data, data.companyId);

    return response;
  }
);

export const updateRelease = createAsyncThunk(
  "releases/updateRelease",
  async (data) => {
    const response = await api.updateRelease(data, data.id);

    return response;
  }
);

const releasesSlice = createSlice({
  name: "releases",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchReleases.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchReleases.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.releases = action.payload;
      })
      .addCase(fetchReleases.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewRelease.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNewRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.releases.unshift(action.payload);
      })
      .addCase(addNewRelease.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateRelease.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateRelease.fulfilled, (state, action) => {
        const index = state.releases.findIndex(
          (release) => release.id === action.payload.id
        );
        state.status = "succeeded";
        if (index !== -1)
          state.releases[index] = {
            ...state.releases[index],
            name: action.payload.name,
            releaseDate: action.payload.releaseDate,
          };
      })
      .addCase(updateRelease.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { releasesUpdated } = releasesSlice.actions;

export default releasesSlice.reducer;

export const selectAllReleases = (state) => state.releases.releases;

export const selectReleaseById = (state, releaseId) =>
  state.releases.releases.find((release) => release.id === releaseId);
