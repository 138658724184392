import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Wrapper, Label, Error } from "./styles";

const InputText = ({
  type = "text",
  name,
  label,
  placeholder = "",
  defaultValue = "",
  register,
  validation,
  disabled,
  errors,
  autoComplete = "on",
}) => {
  const { t } = useTranslation();
  const inputProps = {
    type,
    name,
    placeholder,
    defaultValue,
    autoComplete,
  };

  const displayErrors = () => {
    const message = {
      required: t("formErrors.required"),
      minLength: t("formErrors.minLength", { n: validation.minLength }),
      maxLength: t("formErrors.maxLength", { n: validation.maxLength }),
      default: errors.message,
    };

    return <Error>{message[errors.type] || message["default"]}</Error>;
  };

  return (
    <Wrapper disabled={disabled} hasErrors={errors}>
      <Label htmlFor={name}>{label}</Label>
      {type === "textarea" ? (
        <textarea {...inputProps} {...register(name, validation)} />
      ) : (
        <input
          {...inputProps}
          {...register(name, validation)}
          disabled={disabled}
        />
      )}
      {errors && displayErrors()}
    </Wrapper>
  );
};

InputText.propTypes = {
  type: PropTypes.oneOf([
    "email",
    "text",
    "number",
    "tel",
    "password",
    "textarea",
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default InputText;
