import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  margin: 0 0 20px;

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 5px 14px;
    border: 1px solid;
    border-color: ${({ hasErrors }) => (hasErrors ? "red" : "var(--black)")};
    border-radius: 4px;
    font-size: 14px;

    &:disabled {
      opacity: 0.5;
    }
  }

  textarea {
    min-height: 100px;
  }
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 6px;
  font-size: 14px;
  font-weight: 600;
`;

export const Error = styled.p`
  display: block;
  font-weight: 600;
  color: red;
`;
