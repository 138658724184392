import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const Icon = ({ src, size = "24px" }) => <Wrapper size={size} src={src} />;

Icon.propTypes = {
  size: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export default Icon;
