import styled, { css } from "styled-components";

export const Wrapper = styled.section`
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadows.level1};
  border-radius: 4px;
  margin: 0;
  width: 100%;
  ${({ size }) => {
    const styles = {
      s: css`
        width: 350px;
        max-width: 400px;
      `,
      m: css`
        min-width: 350px;
        max-width: 700px;
      `,
      l: css`
        min-width: 700px;
        max-width: 1200px;
      `,
      default: "max-width: 1200px;",
    };
    return styles[size] || styles["default"];
  }}
  ${({ bgColor }) => {
    const styles = {
      white: css`
        background-color: var(--content-box-bg);
      `,
      gradient: css`
        overflow: hidden;
        background-image: linear-gradient(
          -45deg,
          rgba(82, 209, 57, 1) 0%,
          rgba(112, 245, 112, 1) 100%
        );

        h3 {
          margin: 0 0 12px;
          font-size: 32px;
          font-weight: 800;
          color: var(--white);
        }

        > main > p {
          margin: 0 0 24px;
          font-size: 20px;
          font-weight: 600;
          color: var(--white);
        }

        svg {
          position: absolute;
          right: -5%;
          top: 10%;
          width: 50%;
          height: auto;

          path {
            fill: white;
          }
        }
      `,
      default: "background-color: var(--content-box-bg);",
    };
    return styles[bgColor] || styles["default"];
  }}

  padding: 24px;
  ${({ clickable }) => clickable && "cursor: pointer;"}

  &:last-child {
    margin: 0;
  }

  & + & {
    margin: 30px 0 0;
  }

  & + p {
    margin: 30px 0 0;
  }

  & > *:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  & > *:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  code {
    overflow: hidden;
    position: relative;
    display: block;
    margin: 12px 0 24px;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
    min-height: 150px;
    padding: 24px;
    text-overflow: ellipsis;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--brand);
      opacity: 0.1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: var(--brand);
    }

    p {
      position: relative;
    }

    span {
      display: inline-block;
      width: 10px;
    }

    mark {
      background-color: var(--brand);
      color: var(--white);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 0;
    padding: 24px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: ${({ centeredHeader }) =>
    centeredHeader ? "center" : "space-between"};
  height: 40px;
  margin: 0 0 10px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 0 0 15px;
  }
`;

export const Main = styled.main``;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0;

  a + button,
  button + a,
  a + a,
  button + button {
    margin-left: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 15px 0 0;
  }
`;
