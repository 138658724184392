import styled from "styled-components";
import ContentBox from "layout/atoms/ContentBox";

export const Wrapper = styled(ContentBox)`
  position: relative;
  overflow: hidden;
  margin: 0;
  max-width: 2000px;

  > main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Avatar = styled.div`
  margin: 0 12px 0 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: center center url(${({ src }) => src}) no-repeat;
  background-color: var(--white2);
  background-size: cover;
`;

export const Details = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }

  > div {
    width: 40%;
  }

  p {
    marign: 0;
  }
`;
