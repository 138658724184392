import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";

import { Wrapper } from "./styles";

const Comments = ({ boardId, ideaId, commentCount, onCommentAdded }) => {
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.forms.addComment",
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ defaultValues: { text: "" } });
  const [comments, setComments] = useState([]);
  const [counter, setCounter] = useState(commentCount);

  useEffect(() => {
    fetchIdeaComments();
  }, []);

  const fetchIdeaComments = () => {
    return api.getIdeaComments(ideaId, boardId).then((newComments) => {
      setComments(newComments);
    });
  };

  const addComent = (data) => {
    return api
      .addIdeaComment(data, ideaId, boardId)
      .then((response) => {
        reset();
        fetchIdeaComments();
        setCounter(counter + 1);
        onCommentAdded && onCommentAdded();
      })
      .catch((error) => {
        // Show Toast
      });
  };

  return (
    <Wrapper>
      <h4>
        {t("header")} ({counter})
      </h4>
      {comments &&
        comments.map((comment) => (
          <blockquote key={comment.commentDto.id}>
            <h5>
              {comment.author.displayName}{" "}
              <span>
                {comment.commentDto.createdAt.slice(8, 10)}/
                {comment.commentDto.createdAt.slice(5, 7)}/
                {comment.commentDto.createdAt.slice(0, 4)}
              </span>
            </h5>
            <p>{comment.commentDto.shortMessage}</p>
          </blockquote>
        ))}
      <Form onSubmit={handleSubmit(addComent)}>
        <InputText
          type="textarea"
          name="text"
          placeholder={t("fields.message.placeholder")}
          register={register}
          errors={errors.text}
          validation={{
            required: true,
            minLength: 6,
            maxLength: 500,
          }}
        />
        <Button type="submit" size="lg" color="primary">
          {t("buttons.save")}
        </Button>
      </Form>
    </Wrapper>
  );
};

export default Comments;
