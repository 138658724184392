import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Api from "api/Api";
import { useNavigate } from "react-router-dom";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Modal from "layout/atoms/Modal";

const AddBoardModal = ({ open, handleModalClose, boardId, onNewIdeaAdded }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "createNewBoard",
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ defaultValues: { title: "", description: "" } });

  const createBoard = async (data) => {
    const companyId = await api.getCompanyInfo().then((companyInfo) => {
      return companyInfo.companyId;
    });

    const newBoard = await api
      .createNewBoard(data, companyId)
      .then((response) => {
        navigate(`/admin/boards/${response.id}/list`);
      })
      .catch(() => {
        console.log("Error toast");
      });
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, isSubmitSuccessful, reset]);

  const handleCancelButton = () => {
    reset();
    handleModalClose && handleModalClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Form onSubmit={handleSubmit(createBoard)}>
        <ContentBox
          icon="plus-square"
          header={<h3>{t("title")}</h3>}
          footer={
            <>
              <div />
              <div>
                <Button
                  size="lg"
                  color="white"
                  to="/admin/boards"
                  onClick={handleCancelButton}
                >
                  {t("form.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  size="lg"
                  color="primary"
                  onClick={createBoard}
                >
                  {t("form.buttons.save")}
                </Button>
              </div>
            </>
          }
        >
          <InputText
            type="text"
            name="name"
            label={t("form.fields.name.label")}
            placeholder={t("form.fields.name.placeholder")}
            register={register}
            errors={errors.email}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 50,
            }}
          />
        </ContentBox>
      </Form>
    </Modal>
  );
};

export default AddBoardModal;
