import styled from "styled-components";

export const Wrapper = styled.main`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? "center" : "flex-start")};
  justify-content: ${({ alignCenter }) =>
    alignCenter ? "center" : "flex-start"};
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 70px 20px 40px;

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
  }

  h6 {
    font-size: 12px;
    font-weight: 600;
  }

  #ideolo-board {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 100vh;
    padding: 0 40px 40px;
  }
`;
