import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";

const api = new Api();

const initialState = {
  id: null,
  name: "",
  secret: "",
  status: "idle",
  error: null,
};

export const fetchCompany = createAsyncThunk(
  "company/fetchCompany",
  async () => {
    const response = await api.getCompanyInfo().then((companyInfo) => {
      return companyInfo;
    });

    return response;
  }
);

export const fetchCompanySubscription = createAsyncThunk(
  "company/fetchCompanySubscription",
  async (companyId) => {
    const response = await api
      .getCompanySubscription(companyId)
      .then((companySubscription) => {
        return companySubscription;
      });

    return response;
  }
);

export const fetchCompanyPricing = createAsyncThunk(
  "pricing/fetchCompanyPricing",
  async (companyId) => {
    const response = await api.getPricingInfo(companyId).then((pricingInfo) => {
      return pricingInfo;
    });

    return response;
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    resetCompany: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompany.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.companyId;
        state.name = action.payload.companyName;
        state.secret = action.payload.companySecret;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCompanySubscription.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompanySubscription.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscription = action.payload;
      })
      .addCase(fetchCompanySubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCompanyPricing.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyPricing.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pricing = action.payload;
      })
      .addCase(fetchCompanyPricing.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetCompany } = companySlice.actions;

export default companySlice.reducer;

export const selectCompanyInfo = (state) => state.company;
