import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Dropdown from "layout/atoms/Dropdown";
import Label from "layout/atoms/Label";

const Status = ({ tooltip, statusName, canUpdateStatus, onStatusClick }) => {
  const statuses = [
    { name: "Unpublished", value: "NOT_PUBLIC" },
    { name: "Open", value: "OPEN" },
    { name: "Review", value: "REVIEW" },
    { name: "Planned", value: "PLANNED" },
    { name: "In Progress", value: "IN_PROGRESS" },
    { name: "Complete", value: "COMPLETE" },
  ].filter((item) => item.value !== statusName);
  const { t } = useTranslation();

  const handleLabelClick = (e) => {
    onStatusClick && onStatusClick(e);
  };

  return canUpdateStatus ? (
    <Dropdown
      placement="bottom-start"
      trigger={
        <Label size="s" status={statusName}>
          {t(`statuses.${statusName}`)}
        </Label>
      }
    >
      {statuses.map((item) => (
        <Label
          key={item.value}
          size="s"
          status={item.value}
          onClick={() => handleLabelClick(item.value)}
        >
          {t(`statuses.${item.value}`)}
        </Label>
      ))}
    </Dropdown>
  ) : (
    <Label size="s" status={statusName}>
      {t(`statuses.${statusName}`)}
    </Label>
  );
};

Status.propTypes = {
  text: PropTypes.string,
  votesNumber: PropTypes.number,
  canUpdateStatus: PropTypes.bool,
  onStatusClick: PropTypes.func,
};

export default Status;
