import styled from "styled-components";

export const Wrapper = styled.nav`
  z-index: ${({ theme }) => theme.zIndices.submenu};
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  width: 100vw;
  height: 60px;
  background: var(--submenu-bg);
  box-shadow: ${({ theme }) => theme.boxShadows.level3};
  transform: translate3d(0, ${({ active }) => (active ? "0" : "100%")}, 0);
  transition: transform 0.6s ease;
  will-change: transform;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: fixed;
    left: 80px;
    flex-direction: column;
    justify-content: flex-start;
    width: 260px;
    height: 100vh;
    transform: translate3d(${({ active }) => (active ? "0" : "-356px")}, 0, 0);
  }
`;

export const Header = styled.div`
  display: none;

  &:before {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: 0 12px 0 0;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    color: var(--white);
    text-transform: uppercase;
    background-color: var(--brand);
    ratio: 1;
    transition: background-color 0.6s ease;
    content: attr(data-letter);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  width: 100vw;
  padding: 0 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const NavItem = styled.li`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  width: 20%;
  height: 45px;

  a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 4px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      background: var(--brand);
      opacity: 0;
      content: "";
      transition: opacity 0.2s ease;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      background: var(--white);
      opacity: 0.1;
      content: "";
      transition: opacity 0.2s ease;
    }

    svg {
      opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
      transition: opacity 0.3s ease;

      path {
        fill: var(--black2);
      }
    }

    p {
      overflow: hidden;
      display: block;
      font-size: 10px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &.active {
      svg {
        opacity: 1;
      }

      &::before {
        opacity: 0.2;
      }
    }

    &:hover {
      svg {
        opacity: 1;
      }

      ::before {
        opacity: 0.2;
      }
    }
  }

  &:last-child {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 0 0 6px;
    width: 100%;

    a {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 12px;

      p {
        width: auto;
        padding-left: 24px;
        font-size: 12px;
      }
    }
  }
`;

export const Footer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: block;
  }
`;
