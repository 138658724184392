import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Modal from "layout/atoms/Modal";

const AddGenericReleasePartModal = ({
  releaseId,
  open,
  handleModalClose,
  onNewReleasePartAdded,
}) => {
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.forms.addGenericReleasePart",
  });
  const {
    register,
    control,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      ideaIds: [],
    },
  });

  const addGenericReleasePart = async (data) => {
    handleModalClose && handleModalClose();
    const ideasAsReleasePart = await api
      .addGenericReleasePart(data, releaseId)
      .then((response) => {
        onNewReleasePartAdded && onNewReleasePartAdded();
      })
      .catch((error) => {
        onNewReleasePartAdded && onNewReleasePartAdded();
      });
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, isSubmitSuccessful, reset]);

  const handleCancelButton = () => {
    reset();
    handleModalClose && handleModalClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <ContentBox header={<h3>{t("header")}</h3>}>
        <Form onSubmit={handleSubmit(addGenericReleasePart)}>
          <InputText
            type="text"
            name="title"
            label={t("fields.title.label")}
            placeholder={t("fields.title.placeholder")}
            register={register}
            errors={errors.title}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 100,
            }}
          />
          <div>
            <Button
              type="button"
              size="lg"
              color="white"
              onClick={handleCancelButton}
            >
              {t("buttons.cancel")}
            </Button>
            <Button type="submit" size="lg" color="primary">
              {t("buttons.save")}
            </Button>
          </div>
        </Form>
      </ContentBox>
    </Modal>
  );
};

AddGenericReleasePartModal.propTypes = {
  releaseId: PropTypes.string,
};

export default AddGenericReleasePartModal;
