import React, { useState, useEffect } from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentBoard } from "store/currentBoardSlice";
import { selectAuthInfo } from "store/authSlice";
import { addToast } from "store/toastsSlice";

import Api from "api/Api";
import MainContainer from "layout/atoms/MainContainer";
import Button from "layout/atoms/Button";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import InputText from "layout/atoms/InputText";
import Logo from "layout/atoms/Logo";
import Link from "layout/atoms/Link";
import Label from "layout/atoms/Label";

const SignupForm = (props) => {
  let params = useParams();
  let navigate = useNavigate();
  const authInfo = useSelector(selectAuthInfo);
  const dispatch = useDispatch();
  let api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "signupForm",
  });
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  const initState = {
    loggingIn: false,
    email: "",
    password: "",
    company: "",
    ref: "",
    token: localStorage.getItem("authToken"),
  };

  const [state, setState] = useState(initState);

  const handleSignup = (data) => {
    setState({ ...state, loggingIn: true });
    api
      .register(
        data.email,
        data.password,
        data.company,
        localStorage.getItem("ideoloRefCode") || ""
      )
      .then((loginResponse) => {
        setState({ ...state, loggingIn: false, token: loginResponse.token });
        navigate(`/login`);
        dispatch(
          addToast({
            title: "toasts.signUp.title",
            description: "toasts.signUp.description",
            type: "success",
          })
        );
      })
      .catch((error) => {
        setState({ ...state, loggingIn: false });
        dispatch(
          addToast({
            title: "toasts.error.title",
            description: "toasts.error.description",
            type: "error",
          })
        );
      });
  };

  useEffect(() => {
    params.ref && localStorage.setItem("ideoloRefCode", params.ref);
    dispatch(clearCurrentBoard());
  }, [dispatch]);

  return authInfo.isUserLoggedIn ? (
    <Navigate to={`/`} />
  ) : (
    <MainContainer alignCenter>
      <Logo circle />
      <Form onSubmit={handleSubmit(handleSignup)}>
        <ContentBox
          size="s"
          centeredHeader
          header={<h2>{t("title")}</h2>}
          footer={
            <>
              <Link underline>
                {t("consent")}&nbsp;
                <a
                  href="https://ideolo.co/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("consentLink")}
                </a>
              </Link>
              <Button
                type="submit"
                size="lg"
                color="primary"
                disabled={state.loggingIn}
              >
                {t("button")}
              </Button>
            </>
          }
        >
          <InputText
            type="text"
            name="email"
            label={t("emailLabel")}
            register={register}
            errors={errors.email}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 50,
            }}
          />
          <InputText
            type="password"
            name="password"
            label={t("passwordLabel")}
            register={register}
            errors={errors.password}
            validation={{
              required: true,
              minLength: 6,
              maxLength: 50,
            }}
            autocomplete="on"
          />
          <InputText
            type="text"
            name="company"
            label={t("companyLabel")}
            register={register}
            errors={errors.company}
            validation={{
              minLength: 6,
              maxLength: 50,
            }}
          />
          {localStorage.getItem("ideoloRefCode") && (
            <Label size="l" status="open" block>
              {t("promoCodeLabel", {
                promoCode: localStorage.getItem("ideoloRefCode"),
              })}
            </Label>
          )}
        </ContentBox>
        <Link center>
          {t("login")} <NavLink to="/login">{t("loginLink")}</NavLink>
        </Link>
      </Form>
    </MainContainer>
  );
};

export default SignupForm;
