import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: ${({ block }) => (block ? "flex" : "inline-flex")};
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 4px;
  width: auto;
  transition: all 0.6s ease;
  white-space: nowrap;
  ${({ status }) =>
    status &&
    css`
      background-color: var(--status-${status}-light);
      color: var(--status-${status}-medium);
    `};
  ${({ size }) => {
    return {
      s: css`
        padding: 4px 6px;

        p {
          font-size: 10px;
        }
      `,
      m: css`
        padding: 8px 8px 6px;

        p {
          font-size: 12px;
        }
      `,
      l: css`
        padding: 10px 10px 8px;

        p {
          font-size: 14px;
        }
      `,
    }[size];
  }}

  p {
    position: relative;
    margin: 0;
    padding: 0;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: inherit;
  }
`;
