import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0 20px;
  cursor: pointer;

  > div {
    position: relative;
  }

  select {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    outline: none;
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid;
    border-color: inherit;
    background-color: var(--white);
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -12px;
    pointer-events: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 6px;
  font-size: 14px;
  font-weight: 600;
`;

export const Error = styled.p`
  display: block;
  font-weight: 600;
  color: red;
`;
