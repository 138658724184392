import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { selectCurrentBoardData } from "store/currentBoardSlice";

import { CheckboxGroup } from "./styles";

import Api from "api/Api";

import Button from "layout/atoms/Button";
import InputCheckbox from "layout/atoms/InputCheckbox";
import ContentBox from "layout/atoms/ContentBox";
import Form from "layout/atoms/Form";
import Status from "layout/molecules/Status";
import Modal from "layout/atoms/Modal";

const AddIdeasAsReleasePartsModal = ({
  releaseId,
  open,
  handleModalClose,
  onNewReleasePartAdded,
}) => {
  const api = new Api();
  const { t } = useTranslation("translation", {
    keyPrefix: "boardsDetails.forms.addIdeasAsReleaseParts",
  });
  const currentBoardData = useSelector(selectCurrentBoardData);
  const {
    register,
    control,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      ideaIds: [],
    },
  });
  const [checkedValues, setCheckedValues] = useState([]);

  const addIdeasAsReleasePart = async (data) => {
    handleModalClose && handleModalClose();
    const ideasAsReleasePart = await api
      .addIdeasAsReleaseParts({ ideaIds: checkedValues }, releaseId)
      .then((response) => {
        onNewReleasePartAdded && onNewReleasePartAdded();
      })
      .catch((error) => {
        onNewReleasePartAdded && onNewReleasePartAdded();
      });
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, isSubmitSuccessful, reset]);

  const handleCancelButton = () => {
    reset();
    handleModalClose && handleModalClose();
  };

  function handleSelect(checkedName) {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);

    return newNames;
  }

  const sortIdeas = (ideasToSort, categories) => {
    let newArray = [];
    categories.forEach((category) => {
      newArray = [
        ...newArray,
        ...ideasToSort.filter((item) => item.status === category && item),
      ];
    });
    return newArray;
  };

  const sortedIdeas = sortIdeas(currentBoardData.ideas.ideas, [
    "COMPLETE",
    "IN_PROGRESS",
    "PLANNED",
    "REVIEW",
    "OPEN",
    "NOT_PUBLIC",
  ]);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <ContentBox header={<h3>{t("header")}</h3>}>
        <Form onSubmit={handleSubmit(addIdeasAsReleasePart)}>
          <div>
            <CheckboxGroup>
              {sortedIdeas.map((item, index) => (
                <Controller
                  key={item.id}
                  name="ideaIds"
                  render={({ onChange }) => {
                    return (
                      <InputCheckbox
                        checked={checkedValues.includes(item.id)}
                        onChange={() => handleSelect(item.id)}
                      >
                        <span>{item.title}</span>
                        <span>
                          <Status statusName={item.status} />
                        </span>
                      </InputCheckbox>
                    );
                  }}
                  control={control}
                />
              ))}
            </CheckboxGroup>
          </div>
          <div>
            <Button
              type="button"
              size="lg"
              color="white"
              onClick={handleCancelButton}
            >
              {t("buttons.cancel")}
            </Button>
            <Button type="submit" size="lg" color="primary">
              {t("buttons.save")}
            </Button>
          </div>
        </Form>
      </ContentBox>
    </Modal>
  );
};

AddIdeasAsReleasePartsModal.propTypes = {
  releaseId: PropTypes.string,
};

export default AddIdeasAsReleasePartsModal;
