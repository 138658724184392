import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import currentBoardReducer from "./currentBoardSlice";
import companyReducer from "./companySlice";
import boardsReducer from "./boardsSlice";
import releasesReducer from "./releasesSlice";
import toastsReducer from "./toastsSlice";
import userReducer from "./userSlice";
// import ideasReducer from './ideasSlice'
// import usersReducer from './usersSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    company: companyReducer,
    boards: boardsReducer,
    currentBoard: currentBoardReducer,
    releases: releasesReducer,
    toasts: toastsReducer,
    // currentBoard: {
    //     ideas: ideasReducer,
    // },
    // users: usersReducer,
  },
});
