import React from "react";

import { Wrapper } from "./styles";
import Icon from "layout/atoms/Icon";
import LogoSvg from "assets/logo.svg";

const Loader = () => (
  <Wrapper>
    <div></div>
    <div></div>
    <div></div>
  </Wrapper>
);

export default Loader;
