import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "store/userSlice";
import { fetchCompany } from "store/companySlice";
import { selectAuthInfo } from "store/authSlice";

const AdminLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isAuthenticated = !!localStorage.getItem("authToken");
  const authInfo = useSelector(selectAuthInfo);
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    (authInfo.isUserLoggedIn || isAuthenticated) && dispatch(fetchCompany());
    authInfo.isUserLoggedIn && i18n.changeLanguage(userInfo.lang.split(/-|_/));
  }, [dispatch, authInfo]);

  return authInfo.isUserLoggedIn || isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminLayout;
